import CatalogWrapper from '../../common/catalogWrapper/CatalogWrapper'
import CompanyLogo from '../../common/companyLogo/CompanyLogo'
import InvestionBlock from '../../common/investionBlock/InvestionBlock'
import DiscountBlock from '../../../components/common/discountBlock/DiscountBlock'
import Advantages from '../../common/advantages/Advantages'
import { text } from '../../../translate'
import AboutInfo from '../../common/aboutInfo/AboutInfo'
import PartnerLogo from '../../common/partnerLogo/PartnerLogo'
import NewsFP from '../../common/newsFP/Newsfp'
import { useGetAsics } from '../../hooks/useGetAsics'
import PageSpinner from '../../common/pageSpinner/PageSpinner'
import classes from './MainPage.module.scss'
import Courses from '../../common/header/courses/Courses'
import BannersBlock from "../../common/bannersBlock/bannersBlock";
import LazyLoad from "../../common/lazyLoad/lazyLoad";
import {useGetMarketInfo} from "../../hooks/useGetMarketInfo";

export const MainPage = () => {
   const { data: profitData, isLoading: profitLoader } = useGetAsics('/marketing/most_profitable')
   const { data: newItemsData, isLoading: newLoader } = useGetAsics('/marketing/new_products')
   const { data: saleData, isLoading: saleLoader } = useGetAsics('/marketing/sale_items')
   const { data: hitData, isLoading: hitLoader } = useGetAsics('/marketing/bestsellers')
   const { data: discountItem, isLoading: discountLoader } = useGetAsics('/marketing/featured_asic')
   const { data: marketInfo, isLoading: marketLoading } = useGetMarketInfo()
   const lang = 'ru'

   return (
      <div className={classes.mobileHidden}>
         {profitLoader && newLoader && saleLoader && hitLoader && discountLoader ? (
            <PageSpinner />
         ) : (
            <>
               <InvestionBlock data={marketInfo} />
               <CompanyLogo />
               <div className={classes.containerCourses}>
                  {/* <div className='container'> */}
                  <div className={classes.coursesWrapper}>
                     <Courses />
                  </div>
               </div>
               {false &&
                   <LazyLoad>
                     <BannersBlock data={marketInfo} value={1}/>
                   </LazyLoad>
                   }
               {!!hitData.length && (
                   <LazyLoad>
                      <CatalogWrapper
                          name={text[lang].mainPage.hitPayment}
                          link='/asic-miners'
                          items={hitData}
                          isLoading={hitLoader}
                      />
                   </LazyLoad>
               )}

               {!!discountItem &&
                   <LazyLoad>
                      <DiscountBlock discountItem={discountItem} />
                   </LazyLoad>
               }
               {!!newItemsData.length && (
                   <LazyLoad>
                      <CatalogWrapper
                          name={text[lang].mainPage.newItems}
                          link='/asic-miners'
                          items={newItemsData}
                          isLoading={newLoader}
                      />
                   </LazyLoad>
               )}
               {false &&
                   <LazyLoad>
                     <BannersBlock data={marketInfo} value={2}/>
                   </LazyLoad>
               }
               <LazyLoad>
                  <Advantages />
               </LazyLoad>
               {!!profitData.length && (
                   <LazyLoad>
                      <CatalogWrapper
                          name={text[lang].mainPage.topItems}
                          link='/asic-miners'
                          items={profitData}
                          isLoading={profitLoader}
                      />
                   </LazyLoad>
               )}
               <LazyLoad>
                  <AboutInfo />
               </LazyLoad>
               {!!saleData.length && (
                <LazyLoad>
                  <CatalogWrapper
                     name={text[lang].mainPage.sale}
                     link='/asic-miners'
                     items={saleData}
                     isLoading={saleLoader}
                  />
                </LazyLoad>
               )}
               <LazyLoad>
                  <NewsFP />
               </LazyLoad>
               <LazyLoad>
                  <PartnerLogo />
               </LazyLoad>
            </>
         )}
      </div>
   )
}
