import { baseUrl, imageUrl } from '../config/config'

export async function getInfoContainers(){
    try{
        let response = await fetch(baseUrl+'/container-page-info',{
            method:'GET'
        });
        return await response.json()
    }catch (error) {
        console.error(error);
    }
}

export async function setCallMe(phone){
    let data = {
        "phone": phone,
        "utm": localStorage.getItem('utm')
    }
    try{
        let response = await fetch(baseUrl+'/marketing/call-me',{
            method:'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return response
    }catch (error) {
        console.error(error);
    }
}
