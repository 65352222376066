import React from 'react';
import classes from "./errorsAsicsPage.module.css";
import Crums from "../../common/breadCrums/Crums";

const ErrorsAsicsPage = () => {
    return (
        <div className={classes._}>
            <div className={classes.block}>
                <div className={classes.cont}>
                    <Crums data={[['whatsminer-error-code', 'Коды ошибок майнера Whatsminer ASIC']]} />
                    <h1 className={classes.title}>Коды ошибок майнера WHATSMINER ASIC</h1>
                </div>
                <table className={classes.table}
                    style={{borderCollapse: "collapse", width: '100%', height: '100%', borderWidth: '1px', borderStyle: 'solid'}}
                    border="1" width="668" cellSpacing="0" cellPadding="0">
                    <tbody>
                    <tr >
                        <td className="xl63"
                            style={{fontWeight: 800, textAlign: 'center', padding: '8px', background: 'hsla(0, 0%, 50.2%, .1)'}}
                            width="64"
                            height="20">Код
                        </td>
                        <td className="xl68"
                            style={{fontWeight: 800, padding: '8px', background: 'hsla(0, 0%, 50.2%, .1)'}}
                            width="208">Название
                        </td>
                        <td className="xl64"
                            style={{fontWeight: 800, padding: '8px', background: 'hsla(0, 0%, 50.2%, .1)'}}
                            width="396">Описание и способ устранения ошибки
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }} width="64"
                            height="56"><strong>110</strong></td>
                        <td className="xl69"
                            width="208">Ошибка обнаружения оборотов вентилятора на входе
                        </td>
                        <td className="xl72"

                            rowSpan="6" width="396">Не оригинальные или восстановленные вентиляторы могут не выдавать
                            нужных оборотов или изначально быть не рабочими. Рекомендуем заменить их на оригинальные,
                            чтобы исключить возможные проблемы.<br/>Проверить вентилятор на предмет загрязнений.<br/>Убедиться,
                                что ничего не блокирует воздушный поток.<br/>Проверить разъем подключения вентилятора и
                                    его правильность подключения.<br/>Проверить вентилятор на другом асике.<br/>Заменить
                                        вентилятор.<br/>Замените блок питания.</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }} width="64"
                            height="56"><strong>111</strong></td>
                        <td className="xl69"
                            width="208">Ошибка обнаружения оборотов вентилятора на выходе
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }} width="64"
                            height="56"><strong>120</strong></td>
                        <td className="xl69"
                            width="208">Ошибка оборотов вентилятора на входе (Отклонение 2000+）
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }} width="64"
                            height="37"><strong>121</strong></td>
                        <td className="xl69"
                            width="208">Ошибка оборотов вентилятора на выходе (Отклонение 2000+）
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }} width="64"
                            height="37"><strong>130</strong></td>
                        <td className="xl69"
                            width="208">Ошибка оборотов вентилятора на входе (Отклонение 3000+）
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }} width="64"
                            height="37"><strong>131</strong></td>
                        <td className="xl69"
                            width="208">Ошибка оборотов вентилятора на выходе (Отклонение 3000+）
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }}
                            height="96"><strong>140</strong></td>
                        <td className="xl69" 
                            width="208">Слишком высокие обороты вентиляторов
                        </td>
                        <td className="xl67" 
                            width="396">Из-за высокой температуры асик пытается себя охладить увеличением оборотов
                            вентиляторов.<br/>Почистить асик от различного рода загрязнений.<br/>Проверить температуры
                                входящего потока.<br/>Заменить вентиляторы на оригинальные.</td>
                    </tr>
                    <tr >
                        <td className="xl65"  width="64"
                            height="20">&nbsp;</td>
                        <td className="xl66"
                            width="208">&nbsp;</td>
                        <td className="xl67"
                            width="396">&nbsp;</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }} width="64"
                            height="57"><strong>200</strong></td>
                        <td className="xl69"
                            width="208">Ошибка. Отсутствие напряжения с блока питания
                        </td>
                        <td className="xl72"
                            
                            rowSpan="2" width="396">Проверьте кабель подключения асика к сети.<br/>Перепрошить асик через
                            сд карту, а потом через WhatsMinerTool.<br/>Заменить блок питания.</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }} width="64"
                            height="36"><strong>201</strong></td>
                        <td className="xl69"
                            width="208">Несоответствие модели блока питания и файла конфигурации
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65" 
                            width="64" height="104"><strong>202</strong></td>
                        <td className="xl69"  width="208">Ошибка
                            выходного напряжения с блока питания
                        </td>
                        <td className="xl67" 
                            width="396">Проверить напряжение в сети.<br/>Перезапустить асик.<br/>Попробовать поменять
                            розетку/фазу.<br/>Заменить блок питания.</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="36"><strong>203</strong></td>
                        <td className="xl76"
                            
                            rowSpan="2" width="208">Защита блока питания от перегрузки
                        </td>
                        <td className="xl72"
                            
                            rowSpan="2" width="396">Проверить температуру потока воздуха.<br/>Почистить асик от
                            различного рода загрязнений.<br/>Заменить термоинтерфейс.<br/>Сделать оружающую среду более
                                холодной.</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="36"><strong>204</strong></td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }} width="64"
                            height="63"><strong>205</strong></td>
                        <td className="xl69"
                            width="208">Ошибка блока питания из-за перегрузки
                        </td>
                        <td className="xl67" 
                            width="396">Почтисть хеш платы, КПУ и адаптер плат от загрязнений и собрать все обратно.<br/>Проверьте
                            напряжение в сети.</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="68"><strong>206</strong></td>
                        <td className="xl69" 
                            width="208">Низкое напряжение в сети
                        </td>
                        <td className="xl67" 
                            width="396">Данная ошибка говорит о заниженном напряжение в сети, проверьте входящее
                            напряжение.<br/>Попробовать поменять розетку/фазу.</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="57"><span ><strong>207</strong></span></td>
                        <td className="xl69" 
                            width="208">Защита блока питания из-за напряжения в сети
                        </td>
                        <td className="xl67" 
                            width="396">Проверить входное напряжение сети, для стабильной работы блока питания нужно
                            220~240 вольт.<br/>Попробовать поменять розетку/фазу.</td>
                    </tr>
                    <tr >
                        <td className="xl65" 
                            width="64" height="44"><strong>210</strong></td>
                        <td className="xl69"  width="208">Ошибка
                            блока питания
                        </td>
                        <td className="xl67"  width="396">Смотреть
                            статус ошибки блока питания.
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }} width="64"
                            height="45"><strong>213</strong></td>
                        <td className="xl69"
                            width="208">Мощность потребления не соответствует входной мощности
                        </td>
                        <td className="xl72"
                            
                            rowSpan="3" width="396">Замените блок питания.
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }} width="64"
                            height="45"><strong>216</strong></td>
                        <td className="xl69"
                            width="208">Входная мощность не изменялась длительное время
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65" 
                            width="64" height="45"><strong>217</strong></td>
                        <td className="xl69"  width="208">Ошибка
                            включения блока питания
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65" 
                            width="64" height="100"><strong>218</strong></td>
                        <td className="xl69" 
                            width="208">Напряжение в сети ниже 230 вольт
                        </td>
                        <td className="xl67"  width="396">Для
                            работы асика в режиме high нужно 230 вольт, а в сети меньше 230 вольт.
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>233</strong></td>
                        <td className="xl76"
                            
                            rowSpan="3" width="208">Защита блока питания от перегрева
                        </td>
                        <td className="xl72"
                            
                            rowSpan="3" width="396">Проверить температуру окружающей среды.<br/>Выключить асик минут на
                            10, после попробовать еще раз.<br/>Сделать оружающую среду более холодной.</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>234</strong></td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>235</strong></td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>236</strong></td>
                        <td className="xl76"
                            
                            rowSpan="3" width="208">Защита блока питания от перегрузки
                        </td>
                        <td className="xl72"
                            
                            rowSpan="3" width="396">Проверить затяжку винтов шины питания.<br/>Проверить температуру
                            окружающей среды.<br/>Выключить асик минут на 10, после попробовать еще раз.<br/>Сделать
                                оружающую среду более холодной.</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>237</strong></td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="39"><strong>238</strong></td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="84"><strong>239</strong></td>
                        <td className="xl69" 
                            width="208">Защита блока питания от перенапряжения
                        </td>
                        <td className="xl67" 
                            width="396">Блок питания ушел в защиту из-за высокого напряжения.<br/>Проверить напряжение в
                            сети.<br/>Выключить асик минут на 10, после попробовать еще раз.<br/>Сделать оружающую среду
                                более холодной.</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="261"><strong>240</strong></td>
                        <td className="xl69" 
                            width="208">Защита от низкого входящего напряжения
                        </td>
                        <td className="xl67" 
                            width="396">Блок питания ушел в защиту из-за слишком низкого напряжения в сети.<br/>Проверить
                            напряжение в сети.<br/>Выключить асик минут на 10, после попробовать еще раз.<br/>Попробовать
                                поменять розетку/фазу.<br/><br/>Может происходить из-за:<br/><span
                                    >&nbsp; </span>1) Слишком большая нагрузка на
                                    сеть/фазу<br/><span >&nbsp; </span>2) Не справляется
                                        стабилизатор напряжения (если он есть)<br/><span
                                            >&nbsp; </span>3) Стоит релейный стабилизатор
                                            напряжения, из-за его конструкции у него кратковременно просаживается
                                            напряжение, для блока питания этого времени хватает, чтобы уйти в защиту.
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="37"><strong>241</strong></td>
                        <td className="xl69" 
                            width="208">Ошибка стабилизации напряжения
                        </td>
                        <td className="xl66" 
                            width="396">Замените блок питания.
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>243</strong></td>
                        <td className="xl76"
                            
                            rowSpan="3" width="208">Защита блока питания от перегрева
                        </td>
                        <td className="xl72"
                            
                            rowSpan="5" width="396">Проверить температуру окружающей среды.<br/>Выключить асик минут на
                            10, после попробовать еще раз.<br/>Сделать оружающую среду более холодной.</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>244</strong></td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>245</strong></td>
                    </tr>
                    <tr >
                        <td className="xl65" 
                            width="64" height="28"><strong>246</strong></td>
                        <td className="xl76"
                            
                            rowSpan="2" width="208">Защита блока питания от перегрузки из-за напряжения в сети
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65" 
                            width="64" height="40"><strong>247</strong></td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>248</strong></td>
                        <td className="xl76"
                            
                            rowSpan="2" width="208">Защита блока питания от перенапряжения
                        </td>
                        <td className="xl72"
                            
                            rowSpan="4" width="396">Проверить напряжение в сети.<br/>Для оптимальной работы блока питания
                            ему нужно 220~230 Вольт стабильного напряжения.<br/>Попробовать поменять розетку/фазу.</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>249</strong></td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>250</strong></td>
                        <td className="xl76"
                            
                            rowSpan="2" width="208">Защита блока питания от низкого напряжения в сети
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>251</strong></td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="29"><strong>253</strong></td>
                        <td className="xl76"
                            
                            rowSpan="2" width="208">Ошибка вентилятора блока питания
                        </td>
                        <td className="xl72"
                            
                            rowSpan="2" width="396">Проверить вентилятор блока питания.<br/>Заменить вентилятор блока
                            питания<br/>Заменить блок питания.</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="29"><strong>254</strong></td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="31"><strong>255</strong></td>
                        <td className="xl76"
                            
                            rowSpan="2" width="208">Защита блока питания от перегрузки
                        </td>
                        <td className="xl72"
                            
                            rowSpan="2" width="396">Проверить температуру окружающей среды.<br/>Выключить асик минут на
                            10, после попробовать еще раз.<br/>Сделать оружающую среду более холодной.</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="31"><strong>256</strong></td>
                    </tr>
                    <tr >
                        <td className="xl65" 
                            width="64" height="69"><strong>257</strong></td>
                        <td className="xl69"  width="208">Защита
                            блока питания от перегрузки со стороны напряжения в сети
                        </td>
                        <td className="xl67" 
                            width="396">Отключить асик от сети минут на 5.<br/>Заменить блок питания.</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="41"><strong>263</strong></td>
                        <td className="xl69"
                            width="208">Ошибка связи с блоком питания
                        </td>
                        <td className="xl72"
                            
                            rowSpan="2" width="396">Проверьте затяжку винтов шины питания.<br/>Проверьте шлейф
                            подключения блока птания к КПУ.<br/>Замените блок питания.</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="41"><strong>264</strong></td>
                        <td className="xl69"
                            width="208">Ошибка связи с блоком питания
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="40"><strong>267</strong></td>
                        <td className="xl69" 
                            width="208">Критическая ошибка в работе блока питания
                        </td>
                        <td className="xl67" 
                            width="396">Свяжитесь с техническим специалистом или отправьте блок питания в ремонт.
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="99"><strong>268</strong></td>
                        <td className="xl69" 
                            width="208">Защита блока питания от перегрузки со стороны хеш-плат
                        </td>
                        <td className="xl70" 
                            width="396">Провеьте температуру окружающей среды.<br/>Проверьте затяжку винтов шины питания.<br/>Проверьте
                            соединения на предмет короткого замыкания.<br/>Сделать оружающую среду более холодной.</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="64"><strong>269</strong></td>
                        <td className="xl69" 
                            width="208">Защита блока питания от перегрузки из-за напряжения в сети
                        </td>
                        <td className="xl71"
                            
                            width="396">Проверить напряжение в сети. Для оптимальной работы блока питания ему нужно
                            220~230 вольт стабильного напряжения. Попробовать поменять розетку/фазу.
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="100"><strong>270</strong></td>
                        <td className="xl69" 
                            width="208">Защита блока питания от перенапряжения напряжением в сети
                        </td>
                        <td className="xl67" 
                            width="396">Блок питания ушел в защиту по напряжению.<br/>Проверить напряжение в сети.<br/>Выключить
                            асик минут на 10, после попробовать еще раз.<br/>Попробовать поменять розетку/фазу.<br/>Заменить
                                блок питания.</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="53"><strong>271</strong></td>
                        <td className="xl69" 
                            width="208">Защита от низкого напряжения в сети
                        </td>
                        <td className="xl71"
                            
                            width="396">Проверить напряжение в сети. Для оптимальной работы блока питания ему нужно
                            220~230 вольт стабильного напряжения. Попробовать поменять розетку/фазу.
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="51"><strong>272</strong></td>
                        <td className="xl69" 
                            width="208">Предупреждение о слишком большой выходной мощности блока питания
                        </td>
                        <td className="xl72"
                            
                            rowSpan="2" width="396">Провеьте температуру окружающей среды.<br/>Проверьте затяжку винтов
                            шины питания.<br/>Сделать оружающую среду более холодной.</td>
                    </tr>
                    <tr >
                        <td className="xl65" 
                            width="64" height="51"><strong>273</strong></td>
                        <td className="xl69"  width="208">Слишком
                            большая выходная мощность
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }} width="64"
                            height="59"><strong>274</strong></td>
                        <td className="xl69"
                            width="208">Ошибка вентилятора блока питания
                        </td>
                        <td className="xl67" 
                            width="396">Проверить вентилятор блока питания (ничего ли ему не мешает вращаться).<br/>Заменить
                            вентилятор блока питания.</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="80"><strong>275</strong></td>
                        <td className="xl69" 
                            width="208">Высокая температура блока питания
                        </td>
                        <td className="xl67" 
                            width="396">Блок питания ушел в защиту по температуре.<br/>Проверить температуру окружающей
                            среды.<br/>Выключить асик минут на 10, после попробовать еще раз.<br/>Сделать оружающую среду
                                более холодной.</td>
                    </tr>
                    <tr >
                        <td className="xl65"  width="64"
                            height="20">&nbsp;</td>
                        <td className="xl66"
                            width="208">&nbsp;</td>
                        <td className="xl67"
                            width="396">&nbsp;</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="41"><strong>300</strong></td>
                        <td className="xl69" 
                            width="208">Датчик температуры платы SM0 не обнаружен
                        </td>
                        <td className="xl72"
                            
                            rowSpan="6" width="396">Проверить шлейф от платы адаптера до контрольной платы.<br/>Поменять
                            две платы местами, если ошибка последует за платой, то плату в ремонт.</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="41"><strong>301</strong></td>
                        <td className="xl69" 
                            width="208">Датчик температуры платы SM1 не обнаружен
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="41"><strong>302</strong></td>
                        <td className="xl69" 
                            width="208">Датчик температуры платы SM2 не обнаружен
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65" 
                            width="64" height="52"><strong>320</strong></td>
                        <td className="xl69"  width="208">Ошибка
                            считывания температуры с палты SM0
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65" 
                            width="64" height="52"><strong>321</strong></td>
                        <td className="xl69"  width="208">Ошибка
                            считывания температуры с палты SM1
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65" 
                            width="64" height="52"><strong>322</strong></td>
                        <td className="xl69"  width="208">Ошибка
                            считывания температуры с палты SM2
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="73"><strong>329</strong></td>
                        <td className="xl69" 
                            width="208">Ошибка связи с датчиком температуры контрольной платы
                        </td>
                        <td className="xl67" 
                            width="396">Проверьте шлейф и места подключения блока питания к контрольной платы.<br/>Замените
                            контрольную плату.<br/>Замените блок питания.</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>350</strong></td>
                        <td className="xl69"
                            width="208">Защита от перегрева платы SM0
                        </td>
                        <td className="xl72"
                            
                            rowSpan="4" width="396">Из-за высокой температуры платы ушли в защиту.<br/>Проверить
                            температуру окружающей среды.<br/>Сделать оружающую среду более холодной.<br/>Замените
                                термоинтерфейс плат.</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>351</strong></td>
                        <td className="xl69"
                            width="208">Защита от перегрева платы SM1
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>352</strong></td>
                        <td className="xl69"
                            width="208">Защита от перегрева платы SM2
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>360</strong></td>
                        <td className="xl69"
                            width="208">Перегрев хэш плат
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"  width="64"
                            height="20">&nbsp;</td>
                        <td className="xl66"
                            width="208">&nbsp;</td>
                        <td className="xl67"
                            width="396">&nbsp;</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }} width="64"
                            height="35"><strong>410</strong></td>
                        <td className="xl69"
                            width="208">Ошибка обнаружения файла конфигурации платы SM0
                        </td>
                        <td className="xl72"
                            rowSpan="3" width="396">Перепрошить асик через sd-карту, потом через WhatsMinerTool.<br/>Проверить
                            адаптер плат и его шлейф.<br/>Проверить затяжку винтов шины питания.<br/>Попробовать заменить
                                КПУ.<br/>Попробовать заменить блок питания.</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }} width="64"
                            height="35"><strong>411</strong></td>
                        <td className="xl69"
                            width="208">Ошибка обнаружения файла конфигурации платы SM1
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }} width="64"
                            height="35"><strong>412</strong></td>
                        <td className="xl69"
                            width="208">Ошибка обнаружения файла конфигурации платы SM2
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65" 
                            width="64" height="44"><strong>420</strong></td>
                        <td className="xl69"  width="208">Ошибка
                            анализа файла конфигурации платы SM0
                        </td>
                        <td className="xl72"
                            
                            rowSpan="9" width="396">Перепрошить асик через sd-карту, потом через WhatsMinerTool.
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65" 
                            width="64" height="44"><strong>421</strong></td>
                        <td className="xl69"  width="208">Ошибка
                            анализа файла конфигурации платы SM1
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65" 
                            width="64" height="44"><strong>422</strong></td>
                        <td className="xl69"  width="208">Ошибка
                            анализа файла конфигурации платы SM2
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65" 
                            width="64" height="44"><strong>430</strong></td>
                        <td className="xl69"  width="208">Ошибка
                            данных чипов на плате SM0
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65" 
                            width="64" height="44"><strong>431</strong></td>
                        <td className="xl69"  width="208">Ошибка
                            данных чипов на плате SM1
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65" 
                            width="64" height="44"><strong>432</strong></td>
                        <td className="xl69"  width="208">Ошибка
                            данных чипов на плате SM2
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }} width="64"
                            height="44"><strong>440</strong></td>
                        <td className="xl69"
                            width="208">Ошибка файла конфигурации чипа X на плате SM0
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }} width="64"
                            height="44"><strong>441</strong></td>
                        <td className="xl69"
                            width="208">Ошибка файла конфигурации чипа X на плате SM1
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }} width="64"
                            height="44"><strong>442</strong></td>
                        <td className="xl69"
                            width="208">Ошибка файла конфигурации чипа X на плате SM2
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }} width="64"
                            height="35"><strong>450</strong></td>
                        <td className="xl69"
                            width="208">Ошибка верификации файла конфигурации платы SM0
                        </td>
                        <td className="xl72"
                            
                            rowSpan="3" width="396">Проверить шлейф адаптера плат к КПУ.<br/>Перепрошить асик через
                            sd-карту, потом через WhatsMinerTool.</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }} width="64"
                            height="35"><strong>451</strong></td>
                        <td className="xl69"
                            width="208">Ошибка верификации файла конфигурации платы SM1
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }} width="64"
                            height="35"><strong>452</strong></td>
                        <td className="xl69"
                            width="208">Ошибка верификации файла конфигурации платы SM2
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"  width="64"
                            height="20">&nbsp;</td>
                        <td className="xl66"
                            width="208">&nbsp;</td>
                        <td className="xl67"
                            width="396">&nbsp;</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>510</strong></td>
                        <td className="xl69"
                            width="208">Ошибка модели платы SM0
                        </td>
                        <td className="xl72"
                            
                            rowSpan="6" width="396">Модель хеш-платы не подходит к данному асику.<br/>Замените хеш плату
                            на подходящую.</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>511</strong></td>
                        <td className="xl69"
                            width="208">Ошибка модели платы SM1
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>512</strong></td>
                        <td className="xl69"
                            width="208">Ошибка модели платы SM2
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>520</strong></td>
                        <td className="xl69"  width="208">Не
                            подходящая модель платы SM0
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>521</strong></td>
                        <td className="xl69"  width="208">Не
                            подходящая модель платы SM1
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>522</strong></td>
                        <td className="xl69"  width="208">Не
                            подходящая модель платы SM2
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="39"><strong>530</strong></td>
                        <td className="xl69" 
                            width="208">Плата SM0 не найдена
                        </td>
                        <td className="xl72"
                            
                            rowSpan="3" width="396">Проверить затяжку болтов на шине питания.<br/>Проверить подключение
                            шлейфа адаптера плат к КПУ.<br/>Попробовать поменять КПУ на новую, если ошибка пропала, то
                                КПУ в ремонт.<br/>Поменять две платы местами, если ошибка ушла за платой, то плату в
                                    ремонт.</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="39"><strong>531</strong></td>
                        <td className="xl69" 
                            width="208">Плата SM1 не найдена
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="39"><strong>532</strong></td>
                        <td className="xl69" 
                            width="208">Плата SM2 не найдена
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="36"><strong>540</strong></td>
                        <td className="xl69" 
                            width="208">Ошибка считывания id чипов на плате SM0
                        </td>
                        <td className="xl72"
                            
                            rowSpan="3" width="396">Проверить затяжку болтов на шине питания.<br/>Проверить подключение
                            шлейфа адаптера плат к КПУ.<br/>Поменять две платы местами, если ошибка ушла за платой, то
                                плату в ремонт.</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="36"><strong>541</strong></td>
                        <td className="xl69" 
                            width="208">Ошибка считывания id чипов на плате SM1
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="36"><strong>542</strong></td>
                        <td className="xl69" 
                            width="208">Ошибка считывания id чипов на плате SM2
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>550</strong></td>
                        <td className="xl69"  width="208">На
                            плате SM0 есть неисправные чипы
                        </td>
                        <td className="xl72"
                            
                            rowSpan="3" width="396">Поменять две платы местами, если ошибка пойдет за платой, то плату в
                            ремонт.
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>551</strong></td>
                        <td className="xl69"  width="208">На
                            плате SM1 есть неисправные чипы
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>552</strong></td>
                        <td className="xl69"  width="208">На
                            плате SM2 есть неисправные чипы
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="40"><strong>560</strong></td>
                        <td className="xl69" 
                            width="208">Проблема в стабильности работы платы SM0
                        </td>
                        <td className="xl72"
                            
                            rowSpan="3" width="396">Проверить затяжку винтов на шине питания.<br/>Проверить подключение
                            шлейфа адаптера плат к КПУ.<br/>Проверить параметры разгона.<br/>Проверить напряжение в
                                сети.<br/>Поменять две платы местами, если ошибка ушла за платой, то плату в ремонт.</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="40"><strong>561</strong></td>
                        <td className="xl69" 
                            width="208">Проблема в стабильности работы платы SM1
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="40"><strong>562</strong></td>
                        <td className="xl69" 
                            width="208">Проблема в стабильности работы платы SM2
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"  width="64"
                            height="20">&nbsp;</td>
                        <td className="xl66"
                            width="208">&nbsp;</td>
                        <td className="xl67"
                            width="396">&nbsp;</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="184"><strong>600</strong></td>
                        <td className="xl69" 
                            width="208">Высокая температура окружающей среды
                        </td>
                        <td className="xl67"  width="396">При
                            данной ошибке асик может продолжать работать, но во время тюна будет брать меньшие частоты,
                            а во время работы может их занижать.<br/>Данная ошибка может появляться при иммерсионном
                                охлаждение из-за расположения датчика температуры.<br/>Для иммерсионого охлаждения можно
                                    перевернуть асик, таким образом датчик температуры будет в более холодном месте.<br/>Проверить
                                        температуру окружающей среды.<br/>Сделать окружающую среду более холодной.</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>610</strong></td>
                        <td className="xl69" 
                            width="208">Слишком высокая температура окружающей среды для работы в режиме High
                        </td>
                        <td className="xl67" 
                            width="396">Ошибка возникает на профиле High при температуре окружающей среды выше 30
                            градусов. Асик автоматически вернется в профиль Normal.<br/>Проверьте температуру окружающей
                                среды.<br/>Сделать окружающую среды более холодной.</td>
                    </tr>
                    <tr >
                        <td className="xl65"  width="64"
                            height="20">&nbsp;</td>
                        <td className="xl66"
                            width="208">&nbsp;</td>
                        <td className="xl67"
                            width="396">&nbsp;</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }} width="64"
                            height="64"><strong>701</strong></td>
                        <td className="xl69"
                            width="208">Контрольная плата не поддерживает данную модель чипов
                        </td>
                        <td className="xl77"
                            
                            rowSpan="3">Перепрошить асик через sd-карту, потом через WhatsMinerTool.<br/>Проверить
                            затяжку винтов на шине питания.<br/>Попробовать заменить КПУ.</td>
                    </tr>
                    <tr >
                        <td className="xl65" 
                            width="64" height="64"><strong>710</strong></td>
                        <td className="xl76"
                            
                            rowSpan="2" width="208">Перезагрузка контрольной платы из-за ошибки в ее роботе
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65" 
                            width="64" height="64"><strong>712</strong></td>
                    </tr>
                    <tr >
                        <td className="xl65"  width="64"
                            height="20">&nbsp;</td>
                        <td className="xl66"
                            width="208">&nbsp;</td>
                        <td className="xl67"
                            width="396">&nbsp;</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }} width="64"
                            height="44"><strong>800</strong></td>
                        <td className="xl69"
                            width="208">Ошибка сверки контрольной суммы cgminer
                        </td>
                        <td className="xl72"
                            
                            rowSpan="3" width="396">Перепрошить асик через сд карту, потом через WhatsMinerTool.
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }} width="64"
                            height="44"><strong>801</strong></td>
                        <td className="xl69"
                            width="208">Ошибка сверки контрольной суммы системного монитора
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }} width="64"
                            height="44"><strong>802</strong></td>
                        <td className="xl69"
                            width="208">Ошибка сверки контрольной суммы служебных задач
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"  width="64"
                            height="20">&nbsp;</td>
                        <td className="xl66"
                            width="208">&nbsp;</td>
                        <td className="xl67"
                            width="396">&nbsp;</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>2000</strong></td>
                        <td className="xl69"
                            width="208">Конфигурация пулов не найдена
                        </td>
                        <td className="xl67" 
                            width="396">Проверить настройки пулов.
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>2010</strong></td>
                        <td className="xl69"  width="208">Все
                            пулы выключены
                        </td>
                        <td className="xl72"
                            
                            rowSpan="5" width="396">При старте асика эта ошибка является нормой.<br/>Проверить
                            интернет.<br/>Проверить настройки пулов.<br/>Попробовать поменять пул.<br/>Попробовать поменять
                                провайдера (даже если другие устройства работают хорошо).</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>2020</strong></td>
                        <td className="xl69"
                            width="208">Ошибка соединения с пулом №1
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>2021</strong></td>
                        <td className="xl69"
                            width="208">Ошибка соединения с пулом №2
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>2022</strong></td>
                        <td className="xl69"
                            width="208">Ошибка соединения с пулом №3
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65" 
                            width="64" height="100"><strong>2030</strong></td>
                        <td className="xl69"  width="208">Высокий
                            процент реджектов
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>2040</strong></td>
                        <td className="xl69"  width="208">Пул
                            не поддерживает технологию ASICboost
                        </td>
                        <td className="xl67" 
                            width="396">Попробовать поменять пул, так как данный пул не поддерживает ASICboost.
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>2310</strong></td>
                        <td className="xl76"
                            
                            rowSpan="2" width="208">Слишком низкий хешрейт
                        </td>
                        <td className="xl72"
                            
                            rowSpan="4" width="396">Проверить напряжение в сети, оно должно быть стабильными и в районе
                            220~240 вольт.<br/>Проверить интернет на предмет обрывов соединения.<br/>Проверить температуру
                                окружающей среды, плат и чипов.</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>2320</strong></td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>2340</strong></td>
                        <td className="xl76"
                            
                            rowSpan="2" width="208">Большой процент потерь хешрейта
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>2350</strong></td>
                    </tr>
                    <tr >
                        <td className="xl65"  width="64"
                            height="20">&nbsp;</td>
                        <td className="xl66"
                            width="208">&nbsp;</td>
                        <td className="xl67"
                            width="396">&nbsp;</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>5110</strong></td>
                        <td className="xl69"
                            width="208">Тайм-аут тюна на плате SM0
                        </td>
                        <td className="xl72"
                            
                            rowSpan="3" width="396">Перезагрузить майнер.
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>5111</strong></td>
                        <td className="xl69"
                            width="208">Тайм-аут тюна на плате SM1
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>5112</strong></td>
                        <td className="xl69"
                            width="208">Тайм-аут тюна на плате SM2
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }} width="64"
                            height="33"><strong>5070</strong></td>
                        <td className="xl69"
                            width="208">Слишком быстрая скорость водного потока на плате SM0
                        </td>
                        <td className="xl74"
                            
                            rowSpan="3" width="396">Проверьте скорость потока воды.
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }} width="64"
                            height="33"><strong>5071</strong></td>
                        <td className="xl69"
                            width="208">Слишком быстрая скорость водного потока на плате SM1
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }} width="64"
                            height="33"><strong>5072</strong></td>
                        <td className="xl69"
                            width="208">Слишком быстрая скорость водного потока на плате SM2
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"  width="64"
                            height="20">&nbsp;</td>
                        <td className="xl66"
                            width="208">&nbsp;</td>
                        <td className="xl67"
                            width="396">&nbsp;</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="73"><strong>8410</strong></td>
                        <td className="xl69" 
                            width="208">Ошибка версии прошивки (Пример: асик серии M2x с прошивкой от серии M3x)
                        </td>
                        <td className="xl67" 
                            width="396">Скачать верную версию прошивки.<br/>Перепрошить асик через sd-карту, потом через
                            WhatsMinerTool.</td>
                    </tr>
                    <tr >
                        <td className="xl65" 
                            width="64" height="33"><strong>100001</strong></td>
                        <td className="xl69"  width="208">Ошибка
                            /antiv/signature
                        </td>
                        <td className="xl72"
                            
                            rowSpan="3" width="396">Перепрошить асик через sd-карту, потом через WhatsMinerTool.
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65" 
                            width="64" height="32"><strong>100002</strong></td>
                        <td className="xl69"  width="208">Ошибка
                            /antiv/dig/initd.dig
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="31"><strong>100003</strong></td>
                        <td className="xl69"
                            width="208">Ошибка /antiv/dig/pf_partial.dig
                        </td>
                    </tr>
                    <tr >
                        <td  height="20">&nbsp;</td>
                        <td >&nbsp;</td>
                        <td >&nbsp;</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }} width="64"
                            height="67"><strong>0x0001</strong></td>
                        <td className="xl69"
                            width="208">Низкое напряжение в сети
                        </td>
                        <td className="xl67" 
                            width="396">Проверить входное напряжение сети, для стабильной работы блока питания нужно
                            220~240 вольт. Попробовать поменять розетку/фазу.
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65" 
                            width="64" height="37"><strong>0x0002</strong></td>
                        <td className="xl76"
                            
                            rowSpan="2" width="208">Защита блока питания от перегрева силового радиатора
                        </td>
                        <td className="xl72"
                            
                            rowSpan="4" width="396">Отключить питание на 15 минут.<br/>После включить обратно, если
                            ошибка будет повторяться, то блок питания в ремонт.</td>
                    </tr>
                    <tr >
                        <td className="xl65" 
                            width="64" height="37"><strong>0x0004</strong></td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>0x0008</strong></td>
                        <td className="xl69" 
                            width="208">Защита блока питания от перегрева
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }} width="64"
                            height="20"><strong>0x0010</strong></td>
                        <td className="xl69"
                            width="208">Защита блока питания от перегрузки со стороны напряжения в сети
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }} width="64"
                            height="56"><strong>0x0020</strong></td>
                        <td className="xl69"
                            width="208">Низкое напряжение на выходе с блока питания
                        </td>
                        <td className="xl67" 
                            width="396">Низкое выходное напряжение.<br/>Проверьте напряжение в сети.<br/>Замените блок
                            питания.</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>0x0040</strong></td>
                        <td className="xl69" 
                            width="208">Перегрузка блока питания со стороны потребителя (непрерывная нагрузка в 320
                            ампер более 2 секунд)
                        </td>
                        <td className="xl67" 
                            width="396">Проверьте затяжку винтов шины питания.
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                            style={{ border: '1px solid', padding: '8px', textAlign: 'center' }} width="64"
                            height="52"><strong>0x0080</strong></td>
                        <td className="xl69"
                            width="208">Перегрузка блока питания со стороны напряжения в сети
                        </td>
                        <td className="xl67" 
                            width="396">Отключить питание на 15 минут.<br/>После включить обратно, если ошибка будет
                            повторяться, то блок питания в ремонт.</td>
                    </tr>
                    <tr >
                        <td className="xl65" 
                            width="64" height="44"><strong>0x0100</strong></td>
                        <td className="xl76"
                            
                            rowSpan="3" width="208">Перегрузка блока питания по одной цепи (нагрузка в 120 ампер)
                        </td>
                        <td className="xl72"
                            
                            rowSpan="3" width="396">Блок питания в ремонт.
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65" 
                            width="64" height="44"><strong>0x0200</strong></td>
                    </tr>
                    <tr >
                        <td className="xl65" 
                            width="64" height="44"><strong>0x0400</strong></td>
                    </tr>
                    <tr >
                        <td className="xl65" 
                            width="64" height="44"><strong>0x0800</strong></td>
                        <td className="xl69" 
                            width="208">Неисправный вентилятор блока питания
                        </td>
                        <td className="xl67"  width="396">Замените
                            вентилятор блока питания.
                        </td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>0x1000</strong></td>
                        <td className="xl69" 
                            width="208">Перегрузка блока питания со стороны потребителя (непрерывная нагрузка в 310
                            ампер более 5 минут)
                        </td>
                        <td className="xl72"
                            
                            rowSpan="2" width="396">Проверьте блок питания на предмет короткого замыкания.<br/>Ремонт
                            блока питания.</td>
                    </tr>
                    <tr >
                        <td className="xl65"
                             width="64"
                            height="20"><strong>0x2000</strong></td>
                        <td className="xl69" 
                            width="208">Перегрузка блока питания со стороны потребителя (непрерывная нагрузка в 295
                            ампер более 10 минут)
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ErrorsAsicsPage;