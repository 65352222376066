import { useEffect, useState } from 'react'
import axios from 'axios'
import { baseUrl } from '../config/config'

export const useGetAsics = (url = '/asics/') => {
   const [data, setData] = useState([])
   const [isLoading, setIsLoading] = useState(true)
   useEffect(() => {
      axios
         .get(`${baseUrl}${url}?limit=1000`)
         .then((response) => {
            const data = response.data
             if(!!data.length){
                 const sorted = [
                     ...data.filter(item => item.is_in_stock === true),
                     ...data.filter(item => item.is_in_stock === false)
                 ];
                 setData(sorted)
             }else{
                 setData(data)
             }
            setIsLoading(false)
         })
         .catch((error) => {
            console.error('Произошла ошибка:', error)
            setIsLoading(false)
         })
   }, [url])
   return { data, isLoading }
}
