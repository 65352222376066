import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom'
import classes from '../Header.module.scss'

const NewHeaderMenu = () => {
   const navigate = useNavigate()
   //const [openList, setOpenList] = useState(null)
   const menuArr = [
      { name: 'О компании', link: '/about-us' },
      { name: 'Оплата и доставка', link: '/payment' },
      { name: 'Блог', link: '/news' },
      { name: 'Асики', link: '/', items:[
            {name:'Коды ошибок майнера Whatsminer ASIC', link: '/whatsminer-error-code'}
         ] },
      { name: 'Контакты', link: '/contacts' },
      { name: 'Помощь', link: '/faq' },
   ]
   const handleLink = (link) => {
      window.scroll(0, 0)
      navigate(link)
   }
   return (
      <div className={classes.newMenuWrapper}>
         {menuArr.map((item,number) => (
            <div key={item.name} className={classes.menuItem} onClick={() => {if(item.items){}else{handleLink(item.link)}}}>
               {item.name}
               {item.items && <div className={classes.list}>
                  {item.items.map(i =>
                          <div key={i.name} className={classes.listItem} onClick={()=>{handleLink(i.link)}}>{i.name}</div>
                      )}
               </div>}
            </div>
         ))}
      </div>
   )
}

export default NewHeaderMenu
