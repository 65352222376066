import Crums from '../../common/breadCrums/Crums'
import CatalogWrapper from '../../common/catalogWrapper/CatalogWrapper'
import classes from './MinersItemPage.module.scss'
import { text } from '../../../translate'
import Button from '../../common/catalogWrapper/Button/Button'
import ShopIcon from '../../../accests/image/shoppMenuIcon.svg'
import ComparisonIcon from '../../../accests/image/comparison.svg'
import AcceptIcon from '../../../accests/image/acceptIcon.svg'
import React, {useEffect, useState} from 'react'
import DescriptionItem from '../../common/descriptionItem/DescriptionItem'
import ShareIcon from '../../../accests/image/shareIcon.svg'
import SmallSlider from '../../common/smallSlider/SmallSlider'
import { useGetAsics } from '../../hooks/useGetAsics'
import PageSpinner from '../../common/pageSpinner/PageSpinner'
import { addRemoveCompareById, formatingNumber } from '../../common/comFn'
import imgSucc from '../../../accests/image/success.svg'
import SidePopup from '../../common/modalsUtility/SidePopup'
import {useNavigate, useParams} from 'react-router-dom'
import ButtonFavorite from '../../common/catalogWrapper/ButtonFavorite/ButtonFavorite'
import { useGetAsicsReviews } from '../../hooks/useGetAsicsReviews'
import CoinIcon from '../../../accests/image/coinsItem.svg'
import AlgIcon from '../../../accests/image/algorytmItem.svg'
import HashRaitIcon from '../../../accests/image/hashRaitCard.svg'
import ButtonBuyOneClick from "../../common/buttonBuyOneClick/buttonBuyOneClick";
import MediaQuery from "react-responsive";
import ModalCallback from "../../common/ModalCallback/ModalCallback";

const MinersItemPage = () => {
   const redirect = useNavigate()
   const lang = 'ru'
   const { data, isLoading } = useGetAsics()
   const params = useParams()

   const [checkRefetch, setCheckRefetch] = useState(1)
   const dataT = data?.length ? data?.filter((item) => item.slug_name === params.title) : []

   const {
      id,
      title,
      hashrate,
      algorithm,
      coins,
      is_new,
      hit,
      total_price,
      price,
      discount,
      photo_urls,
      is_in_stock,
      hashrate_um,
      video_url,
      description,
      tags,
      characteristics,
      is_used,
      is_on_the_way,
      slug_name
   } = dataT.length === 0 ? {id:0} : dataT[0]

   useEffect(()=>{
      if(id === 0 && !isLoading){
         redirect('/Error404')
      }
   },[id, isLoading])

   const [selectValue, setSelectValue] = useState('Описание')
   const { data: dataReview } = useGetAsicsReviews(id, checkRefetch)
   const [popup, setPopup] = useState(false)
   const selectName = ['Описание', 'Характеристики', 'Отзывы', 'Оплата и доставка']
   const visiterArr = localStorage.getItem('visitedArr')?.split(',').reverse()
   const [showModal, setShowModal] = useState(false)

   const copuFn = (text) => {
      navigator.clipboard.writeText(text)
      setPopup(true)
      setTimeout(() => setPopup(false), 3000)
   }
   let linkHash = ''
   linkHash = video_url?.includes('watch')
      ? video_url.split('=')[1].split('&')[0]
      : video_url?.split('/')[3].split('?')[0] ?? ''

   const chechReview = (arr) => {
      if (arr.length === 1) {
         return 'оценка'
      } else if (arr.length === 2 || arr.length === 3 || arr.length === 4) {
         return 'оценки'
      }
      return 'оценкок'
   }

   const reduceReview = !!dataReview.length
      ? dataReview?.map((item) => item?.rating)?.reduce((acc, number) => acc + number) /
        dataReview?.length
      : 0
   return (
      <div className={'container'}>
         <Crums
            data={[
               ['asic-miners', 'Asic-майнеры'],
               ['', `${title ?? '...'}`],
            ]}
         />
         <div itemScope itemType="https://schema.org/OfferCatalog">
               <div itemProp="itemListElement" itemScope itemType="https://schema.org/Offer">
                  <div>
         {is_in_stock ? <link itemProp="availability" href="https://schema.org/InStock"/>:
                        <link itemProp="availability" href="https://schema.org/OutOfStock"/>
            }
                  </div>
               </div>
         </div>

         {isLoading ? (
            <PageSpinner />
         ) : (
            <>
               <MediaQuery minWidth={1281}>
                  <div className={classes.content}>
                  <div className={classes.infoWrapper}>
                     <SmallSlider photo_urls={photo_urls} />
                  </div>

                  <div className={classes.abioutItemInfo}>
                     <div className={classes.labels}>
                        {!!is_in_stock && (
                           <div className={classes.hit}>
                              {text[lang].mainPage.catalogItem.is_in_stock}
                           </div>
                        )}
                        {!!is_new && (
                           <div className={classes.sallary}>
                              {text[lang].mainPage.catalogItem.isNew}
                           </div>
                        )}
                        {!!hit && (
                           <div className={classes.newItem}>
                              {text[lang].mainPage.catalogItem.hit}
                           </div>
                        )}
                        {!!is_used && (
                            <div className={classes.newItem} style={{background: '#a6b94e', color:'white', border: '1px solid #a6b94e'}}>
                               {text[lang].mainPage.catalogItem.is_used}
                            </div>
                        )}
                        {!!is_on_the_way && (
                            <div className={classes.newItem} style={{color:'rgb(67, 188, 255)', border: '1px solid rgb(67, 188, 255)'}}>
                               {text[lang].mainPage.catalogItem.is_on_the_way}
                            </div>
                        )}
                        {tags?.map((tag) => (
                           <div
                              className={classes.otherTag}
                              key={tag.id}
                              style={{
                                 color: tag.color ?? '#80899c',
                                 border: `1px solid ${tag.color ?? '#80899c'}`,
                              }}
                           >
                              {tag?.value}
                           </div>
                        ))}
                     </div>
                     <div className={classes.description}>
                        <h1 className={classes.name}>{title}</h1>
                        <div className={classes.ratingWrapper}>
                           <div>
                              <svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'>
                                 <path
                                    d='M9.53803 0.47911C9.19773 0.316859 8.80239 0.316859 8.46209 0.47911C8.16791 0.619372 8.00571 0.862903 7.92392 0.996505C7.83936 1.13462 7.75256 1.31056 7.66795 1.48205L5.87151 5.12145L1.85313 5.7088C1.66397 5.73641 1.46988 5.76474 1.31245 5.80259C1.16015 5.83921 0.878562 5.91842 0.654469 6.15496C0.395227 6.4286 0.273308 6.80461 0.322658 7.17831C0.365318 7.50133 0.546854 7.73071 0.648688 7.84973C0.753953 7.97276 0.894494 8.1096 1.03146 8.24295L3.93796 11.0739L3.25216 15.0724C3.21978 15.261 3.18657 15.4544 3.17378 15.6159C3.16142 15.772 3.14946 16.0645 3.30505 16.3509C3.48501 16.6822 3.80491 16.9146 4.17563 16.9833C4.49614 17.0427 4.77054 16.941 4.91524 16.8809C5.06486 16.8189 5.23854 16.7275 5.40784 16.6384L9.00006 14.7493L12.5923 16.6384C12.7616 16.7275 12.9353 16.8189 13.0849 16.8809C13.2296 16.941 13.504 17.0427 13.8245 16.9833C14.1952 16.9146 14.5151 16.6822 14.6951 16.3509C14.8507 16.0645 14.8387 15.772 14.8263 15.6159C14.8136 15.4544 14.7803 15.261 14.748 15.0725L14.0622 11.0739L16.9687 8.24292C17.1056 8.10957 17.2462 7.97275 17.3514 7.84973C17.4533 7.73071 17.6348 7.50133 17.6775 7.17831C17.7268 6.80461 17.6049 6.4286 17.3457 6.15496C17.1216 5.91842 16.84 5.83922 16.6877 5.80259C16.5302 5.76474 16.3361 5.73641 16.147 5.7088L12.1286 5.12145L10.3322 1.48211C10.2476 1.31061 10.1608 1.13463 10.0762 0.996505C9.99441 0.862903 9.83221 0.619372 9.53803 0.47911Z'
                                    fill={'#F3CB67'}
                                 />
                              </svg>
                           </div>

                           <p>{!!dataReview.length ? reduceReview.toFixed(1) : 'Нет отзывов'}</p>
                           <span
                              onClick={() => {
                                 setSelectValue('Отзывы')
                                 window.scroll(900, 900)
                              }}
                           >
                              {!!dataReview.length
                                 ? `${dataReview.length} ${chechReview(dataReview)}`
                                 : 'Оставить отзыв'}
                           </span>
                        </div>
                        <div className={classes.descriptionValue}>
                           <img src={HashRaitIcon} alt='coins' />
                           {text[lang].mainPage.catalogItem.hashRate}:
                           <span>
                              {hashrate} {hashrate_um?.value ?? ''}
                           </span>
                        </div>
                        <div className={classes.descriptionValue}>
                           <img src={AlgIcon} alt='coins' />
                           {text[lang].mainPage.catalogItem.algorytm}:
                           <span>{algorithm?.value || ''}</span>
                        </div>
                        <div className={classes.descriptionValue}>
                           <img src={CoinIcon} alt='coins' />
                           {text[lang].mainPage.catalogItem.coins}:
                           <span>{coins?.map((item) => item.value).join('/')}</span>
                        </div>
                     </div>
                     <div className={classes.inHave}>
                        {is_in_stock ? (
                            <>
                               <div className={classes.acceptImg}>
                                  <img src={AcceptIcon} alt='accept' />
                               </div>
                               <p>Есть в наличии</p>
                            </>
                        ) : (
                            <p>Нет в наличии</p>
                        )}
                     </div>

                     <div className={classes.pricesWrapper}>
                        {is_in_stock ?
                        <>
                           <div className={classes.discountWrapper}>
                              {!!discount && (
                                  <div className={classes.contDis}>
                                     <div className={classes.price}>{formatingNumber(price)}</div>
                                     <div className={classes.salleryPrice}>
                                        -{formatingNumber(discount)} ₽
                                     </div>
                                  </div>
                              )}
                           </div>
                           <div className={classes.disWrap}>
                              <div className={classes.total_price}>
                                 {formatingNumber(total_price)} ₽
                              </div>
                           </div>
                        </>:
                            <div className={classes.disWrap}>
                               <div className={classes.total_price}>
                                  {text[lang].mainPage.sendPrice}
                               </div>
                            </div>
                        }

                        <div className={classes.activeBlock}>
                           {is_in_stock && <Button itemId={id} photo={photo_urls} name={title}>
                              <img src={ShopIcon} alt='shopIcon' />
                              {text[lang].mainPage.inBasket}
                           </Button>}
                           {!is_in_stock && <Button onClick={()=>{setShowModal(true)}}>
                              {text[lang].mainPage.order}
                           </Button>}
                           <ButtonBuyOneClick url={window.location.href}/>
                        </div>
                        <div className={classes.buttonsBlock2}>
                           <div
                               className={`${classes.labelActive} ${
                                   !!localStorage
                                       .getItem('compareArr')
                                       ?.split(',')
                                       .filter((item) => +item === +id).length
                                       ? classes.compareActive
                                       : ''
                               }`}
                               onClick={() => addRemoveCompareById(id)}
                           >
                              <img src={ComparisonIcon} alt='ComparisonIcon' />
                              <span>Сравнить</span>
                           </div>
                           <ButtonFavorite itemId={id} tooltip={true} />
                           <div
                               className={classes.labelActive}
                               onClick={() => copuFn(window.location)}
                           >
                              <img src={ShareIcon} alt='share' />
                              <span>Поделиться</span>
                           </div>
                        </div>
                     </div>

                     {!!linkHash.length && (
                        <div className={classes.video}>
                           <iframe
                              src={`https://www.youtube.com/embed/${linkHash}`}
                              title='YouTube video player'
                              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                              referrerPolicy='strict-origin-when-cross-origin'
                              allowFullScreen
                           ></iframe>
                        </div>
                     )}
                  </div>
               </div>
               </MediaQuery>
               <MediaQuery maxWidth={1280}>
                  <div className={classes.mobileContent}>
                  <SmallSlider photo_urls={photo_urls} />

                  <div className={classes.labels}>
                     {!!is_in_stock && (
                        <div className={classes.hit}>
                           {text[lang].mainPage.catalogItem.is_in_stock}
                        </div>
                     )}
                     {!!is_new && (
                        <div className={classes.sallary}>
                           {text[lang].mainPage.catalogItem.isNew}
                        </div>
                     )}
                     {!!hit && (
                        <div className={classes.newItem}>{text[lang].mainPage.catalogItem.hit}</div>
                     )}
                     {!!is_used && (
                         <div className={classes.newItem} style={{background: '#a6b94e', color:'white', border: '1px solid #a6b94e'}}>
                            {text[lang].mainPage.catalogItem.is_used}
                         </div>
                     )}
                     {tags?.map((tag) => (
                        <div
                           className={classes.otherTag}
                           key={tag.id}
                           style={{
                              color: tag.color ?? '#80899c',
                              border: `1px solid ${tag.color ?? '#80899c'}`,
                           }}
                        >
                           {tag?.value}
                        </div>
                     ))}
                  </div>
                  <h1 className={classes.name}>{title}</h1>
                  <div className={classes.ratingWrapper}>
                     <div>
                        <svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'>
                           <path
                              d='M9.53803 0.47911C9.19773 0.316859 8.80239 0.316859 8.46209 0.47911C8.16791 0.619372 8.00571 0.862903 7.92392 0.996505C7.83936 1.13462 7.75256 1.31056 7.66795 1.48205L5.87151 5.12145L1.85313 5.7088C1.66397 5.73641 1.46988 5.76474 1.31245 5.80259C1.16015 5.83921 0.878562 5.91842 0.654469 6.15496C0.395227 6.4286 0.273308 6.80461 0.322658 7.17831C0.365318 7.50133 0.546854 7.73071 0.648688 7.84973C0.753953 7.97276 0.894494 8.1096 1.03146 8.24295L3.93796 11.0739L3.25216 15.0724C3.21978 15.261 3.18657 15.4544 3.17378 15.6159C3.16142 15.772 3.14946 16.0645 3.30505 16.3509C3.48501 16.6822 3.80491 16.9146 4.17563 16.9833C4.49614 17.0427 4.77054 16.941 4.91524 16.8809C5.06486 16.8189 5.23854 16.7275 5.40784 16.6384L9.00006 14.7493L12.5923 16.6384C12.7616 16.7275 12.9353 16.8189 13.0849 16.8809C13.2296 16.941 13.504 17.0427 13.8245 16.9833C14.1952 16.9146 14.5151 16.6822 14.6951 16.3509C14.8507 16.0645 14.8387 15.772 14.8263 15.6159C14.8136 15.4544 14.7803 15.261 14.748 15.0725L14.0622 11.0739L16.9687 8.24292C17.1056 8.10957 17.2462 7.97275 17.3514 7.84973C17.4533 7.73071 17.6348 7.50133 17.6775 7.17831C17.7268 6.80461 17.6049 6.4286 17.3457 6.15496C17.1216 5.91842 16.84 5.83922 16.6877 5.80259C16.5302 5.76474 16.3361 5.73641 16.147 5.7088L12.1286 5.12145L10.3322 1.48211C10.2476 1.31061 10.1608 1.13463 10.0762 0.996505C9.99441 0.862903 9.83221 0.619372 9.53803 0.47911Z'
                              fill={'#F3CB67'}
                           />
                        </svg>
                     </div>

                     <p>{reduceReview.toFixed(1)}</p>
                     <span
                        onClick={() => {
                           setSelectValue('Отзывы')
                           window.scroll(900, 900)
                        }}
                     >
                        {dataReview.length} {chechReview(dataReview)}
                     </span>
                  </div>
                  <div className={classes.descriptionValue}>
                     {text[lang].mainPage.catalogItem.hashRate}:
                     <span>
                        {hashrate} {hashrate_um?.value ?? ''}
                     </span>
                  </div>
                  <div className={classes.descriptionValue}>
                     {text[lang].mainPage.catalogItem.algorytm}:
                     <span>{algorithm?.value || ''}</span>
                  </div>
                  <div className={classes.descriptionValue}>
                     {text[lang].mainPage.catalogItem.coins}:
                     <span>{coins?.map((item) => item.value).join('/')}</span>
                  </div>
                  <div className={classes.inHave}>
                     {is_in_stock ? (
                         <>
                            <div className={classes.acceptImg}>
                               <img src={AcceptIcon} alt='accept' />
                            </div>
                            <p>В наличии</p>
                         </>
                     ) : (
                         <p>Нет в наличии</p>
                     )}
                  </div>
                  <div className={classes.pricesWrapper}>
                     {is_in_stock ?
                        <>
                           <div className={classes.discountWrapper}>
                              {!!discount && (
                                  <div className={classes.contDis}>
                                     <div className={classes.price}>{formatingNumber(price)}</div>
                                     <div className={classes.salleryPrice}>
                                        -{formatingNumber(discount)} ₽
                                     </div>
                                  </div>
                              )}
                           </div>
                           <div className={classes.disWrap}>
                              <div className={classes.total_price}>{formatingNumber(total_price)} ₽</div>
                           </div>
                        </>:
                         <div className={classes.disWrap}>
                            <div className={classes.total_price}>
                               {text[lang].mainPage.sendPrice}
                            </div>
                         </div>
                     }

                     <div className={classes.activeBlock}>
                        {is_in_stock && <Button itemId={id} photo={photo_urls} name={title}>
                           <img src={ShopIcon} alt='shopIcon' />
                           {text[lang].mainPage.inBasket}
                        </Button>}
                        {!is_in_stock && <Button onClick={()=>{setShowModal(true)}}>
                           {text[lang].mainPage.order}
                        </Button>}
                        <ButtonBuyOneClick url={window.location.href}/>
                     </div>
                     <div className={classes.buttonsBlock2}>
                        <div
                            className={`${classes.labelActive} ${
                                !!localStorage
                                    .getItem('compareArr')
                                    ?.split(',')
                                    .filter((item) => +item === +id).length
                                    ? classes.compareActive
                                    : ''
                            }`}
                            onClick={() => addRemoveCompareById(id)}
                        >
                           <img src={ComparisonIcon} alt='ComparisonIcon' />
                        </div>

                        <ButtonFavorite itemId={id} />
                        <div
                            className={classes.labelActive}
                            onClick={() => copuFn(window.location)}
                        >
                           <img src={ShareIcon} alt='share' />
                        </div>
                     </div>
                  </div>

                  {/* <div className={classes.inHave}>
                     {is_in_stock ? (
                        <>
                           <div className={classes.acceptImg}>
                              <img src={AcceptIcon} alt='accept' />
                           </div>
                           <p>Есть в наличии</p>
                        </>
                     ) : (
                        <p>Нет в наличии</p>
                     )}
                  </div> */}
                  {/* <div className={classes.pricesWrapper}>
                     <div className={classes.discountWrapper}>
                        {!!discount && (
                           <>
                              <div className={classes.price}>{formatingNumber(price)}</div>
                              <div className={classes.salleryPrice}>
                                 -{formatingNumber(discount)} ₽
                              </div>
                           </>
                        )}
                     </div>
                     <div className={classes.total_price}>{formatingNumber(total_price)} ₽</div>
                  </div> */}
                  {/* <div className={classes.activeBlock}>
                     <Button itemId={id} photo={photo_urls}>
                        <img src={ShopIcon} alt='shopIcon' /> {text[lang].mainPage.inBasket}
                     </Button>
                     <div
                        className={`${classes.labelActive} ${
                           !!localStorage
                              .getItem('compareArr')
                              ?.split(',')
                              .filter((item) => +item === +id).length
                              ? classes.compareActive
                              : ''
                        }`}
                        onClick={() => addRemoveCompareById(id)}
                     >
                        <img src={ComparisonIcon} alt='ComparisonIcon' />
                     </div>

                     <ButtonFavorite itemId={id} />
                     <div
                        className={classes.labelActive}
                        // onClick={() => copuFn(window.location)}
                        onClick={() =>
                           navigator.canShare({
                              title: `${title}`,
                              text: `${title}`,
                              url: `${window.location}`,
                           })
                        }
                     >
                        <img src={ShareIcon} alt='share' />
                     </div>
                  </div> */}
                  {!!linkHash.length && (
                     <div className={classes.video}>
                        <iframe
                           src={`https://www.youtube.com/embed/${linkHash}`}
                           title='YouTube video player'
                           allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                           referrerPolicy='strict-origin-when-cross-origin'
                           allowFullScreen
                        ></iframe>
                     </div>
                  )}
                  {/* <div className={classes.select}>
                     {selectName.map((item) => (
                        <div
                           key={item}
                           className={`${classes.option} ${
                              item === selectValue ? classes.activeOption : ''
                           }`}
                           onClick={() => setSelectValue(item)}
                        >
                           {item}
                        </div>
                     ))}
                  </div>
                  <DescriptionItem
                     name={selectValue}
                     characteristics={characteristics}
                     description={description}
                  /> */}
               </div>
               </MediaQuery>
               <div className={classes.select}>
                  {selectName.map((item) => (
                     <div
                        key={item}
                        className={`${classes.option} ${
                           item === selectValue ? classes.activeOption : ''
                        }`}
                        onClick={() => setSelectValue(item)}
                     >
                        {item}
                        {item === 'Отзывы' && <span>{dataReview.length}</span>}
                     </div>
                  ))}
               </div>
               <DescriptionItem
                  name={selectValue}
                  characteristics={characteristics}
                  description={description}
                  id={id}
                  title={title}
                  dataReview={dataReview}
                  checkRefetch={checkRefetch}
                  setCheckRefetch={setCheckRefetch}
                  item={
                     data?.length
                        ? data?.filter((item) => item.slug_name === params.title)[0]
                        : []
                  }
               />
               {!!visiterArr?.length && (
                  <CatalogWrapper
                     name={'Смотрели ранее'}
                     link='none'
                     items={visiterArr
                        ?.map((vis) => data.find((item) => item.id === +vis))
                        .filter((item) => item !== undefined)}
                  />
               )}{' '}
            </>
         )}
         {popup && (
            <SidePopup dataState={true}>
               <>
                  <span>
                     <img src={imgSucc} alt='success' />
                  </span>
                  <span>Успех!</span>
                  <span> Сслылка скопирована!</span>
               </>
            </SidePopup>
         )}
         {showModal && <ModalCallback onClose={() => setShowModal(false)}
                                      modalSlug={id}
                                      type={'asic'}
         />}
      </div>
   )
}

export default MinersItemPage
