import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProfileContext } from '../../../App'
import { FileText, Play } from 'lucide-react'
import Crums from '../../common/breadCrums/Crums';
import SmallSlider from '../../common/smallSlider/SmallSlider';
import DescriptionItem from '../../common/descriptionItem/DescriptionItem';
import CatalogWrapper from '../../common/catalogWrapper/CatalogWrapper';
import PageSpinner from '../../common/pageSpinner/PageSpinner';
import PaymentSelect from '../../common/descriptionItem/paymentSelect/PaymentSelect';
import AcceptIcon from '../../../accests/image/acceptIcon.svg'
import classes from './GeneratorsItemPage.module.scss';
import { useGetGenerators } from '../../hooks/useGetGenerators';
import { toKebabCase, formatingNumber } from '../../common/comFn';
import ModalCallback from '../../common/ModalCallback/ModalCallback';

const GeneratorsItemPage = () => {
    const context = useContext(ProfileContext)
    const redirect = useNavigate();
    const params = useParams();
    const { data, isLoading } = useGetGenerators('/generators');
    const [selectValue, setSelectValue] = useState('Описание');
    const [showModal, setShowModal] = useState(false);
    const [modalGenId, setModalGenId] = useState(null);

    useEffect(() => {
        context.storeDispatch({ type: 'mining_manager', payload: 'mining' })
        return () => {
            context.storeDispatch({ type: 'regular' })
        }
    }, [])

    const dataT = data?.length
        ? data.find((item) => item.slug_name === params.title)
        : [];

    useEffect(() => {
        if (!dataT && !isLoading) {
            redirect('/Error404');
        }
    }, [dataT, isLoading, redirect]);
    if (isLoading || !dataT) {
        return <PageSpinner />;
    }

    const {
        id,
        title,
        price,
        discount,
        total_price,
        description,
        head_items,
        body_items,
        medias,
        attachments,
        tags,
        slug_name,
        is_active,
        video_url
    } = dataT;

    const handleDownload = (storeUrl) => {
        const link = document.createElement('a');
        link.href = storeUrl;
        link.setAttribute('download', '');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleShowModal = (visible, id) => {
        console.log(id)
        setShowModal(visible);
        setModalGenId(id); // Сохраняем переданный slug_name
    };

    const selectName = ['Описание', 'Характеристики', 'Доставка'];



    const videoExtensions = ['.mp4', '.mov', '.avi', '.mkv'];

    /*const videoUrl = medias?.find((media) =>
        videoExtensions.some((ext) => media.store_url.toLowerCase().endsWith(ext))
    )?.store_url;*/
    const videoUrl = video_url;
    const imageUrls = medias?.filter((media) =>
        !videoExtensions.some((ext) => media.store_url.toLowerCase().endsWith(ext))
    ).map((media) => media.store_url);
    console.log(videoUrl)
    return (
        <div className={`container ${classes.pageContainer}`}>
            <Crums
                data={[
                    ['generators', 'Генераторы'],
                    ['', `${title ?? '...'}`],
                ]}
            />
            {/* Основная структура для больших экранов */}
            <div className={classes.content}>
                <div className={classes.infoWrapper}>
                    <SmallSlider photo_urls={imageUrls} />

                    <div className={classes.documents}>
                        {attachments?.map((doc) => (
                            <div
                                className={classes.document}
                                key={doc.id}
                                onClick={() => handleDownload(doc.store_url)}
                            >
                                <div className={classes.documentLeft}>
                                    <FileText />
                                    <span>{doc.name}</span>
                                </div>
                                <span className={classes.downloadText}>Скачать</span>
                            </div>
                        ))}
                    </div>
                </div>

                <div className={classes.aboutItemInfo}>
                    <h1 className={classes.name}>{title}</h1>
                    {head_items?.map((item, index) => (
                        <div key={index} className={classes.descriptionValue}>
                            {item.name}: <span>{item.value}</span>
                        </div>
                    ))}
                    <div className={classes.pricesWrapper}>
                        <div className={classes.discountWrapper}>
                            {!!discount && (
                                <div className={classes.contDis}>
                                    <div className={classes.price}>{formatingNumber(price)}</div>
                                    <div className={classes.salleryPrice}>
                                        -{formatingNumber(discount)} ₽
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={classes.disWrap}>
                            {!!total_price && <div className={classes.total_price}>
                                {formatingNumber(total_price)} ₽
                            </div>}
                            <div className={classes.inHave}>
                                {tags.filter(item=>item.name==='В НАЛИЧИИ').length > 0 ? (
                                    <>
                                        <div className={classes.acceptImg}>
                                            <img src={AcceptIcon} alt='accept' />
                                        </div>
                                        <p>Есть в наличии</p>
                                    </>
                                ) : (
                                    <p>Нет в наличии</p>
                                )}
                            </div>
                        </div>

                        <button className={classes.orderButton} onClick={() => handleShowModal(true, id)}>Заказать</button>
                        <p className={classes.policyText}>
                            Нажимая на кнопку, вы соглашаетесь с{' '}
                            <a href="/rights" className={classes.policyLink}>
                                политикой конфиденциальности
                            </a>
                        </p>
                    </div>
                    {videoUrl && (
                        <div className={classes.videoWrapper}>

                            <iframe
                                src={videoUrl}
                                title='Video player'
                                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                referrerPolicy='strict-origin-when-cross-origin'
                                allowFullScreen
                                frameBorder="0"
                                className={classes.videoPlayer}
                            ></iframe>

                        </div>
                    )}
                </div>
            </div>
            {/* Новая структура для маленьких экранов */}
            <div className={classes.mobileContent}>
                <h1 className={classes.name}>{title}</h1>

                <SmallSlider photo_urls={imageUrls} />

                <div className={classes.headItems}>
                    {head_items?.map((item, index) => (
                        <div key={index} className={classes.descriptionValue}>
                            {item.name}: <span>{item.value}</span>
                        </div>
                    ))}
                </div>
                <div className={classes.inHave}>
                    {tags.filter(item=>item.name==='В НАЛИЧИИ').length > 0 ? (
                        <>
                            <div className={classes.acceptImg}>
                                <img src={AcceptIcon} alt='accept' />
                            </div>
                            <p>Есть в наличии</p>
                        </>
                    ) : (
                        <p>Нет в наличии</p>
                    )}
                </div>
                <div className={classes.pricesWrapper}>
                    <div className={classes.discountWrapper}>
                        {!!discount && (
                            <div className={classes.contDis}>
                                <div className={classes.price}>{formatingNumber(price)}</div>
                                <div className={classes.salleryPrice}>
                                    -{formatingNumber(discount)} ₽
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={classes.disWrap}>
                        {!!total_price && <div className={classes.total_price}>
                            {formatingNumber(total_price)} ₽
                        </div>}

                    </div>

                    <button className={classes.orderButton} onClick={() => handleShowModal(true, id)}>Заказать</button>
                    <p className={classes.policyText}>
                        Нажимая на кнопку, вы соглашаетесь с{' '}
                        <a href="/rights" className={classes.policyLink}>
                            политикой конфиденциальности
                        </a>
                    </p>
                </div>

                <div className={classes.documents}>
                    {attachments?.map((doc) => (
                        <div
                            className={classes.document}
                            key={doc.id}
                            onClick={() => handleDownload(doc.store_url)}
                        >
                            <div className={classes.documentLeft}>
                                <FileText />
                                <span>{doc.name}</span>
                            </div>
                            <span>Скачать</span>
                        </div>
                    ))}
                </div>
                {videoUrl && (
                    <div className={classes.videoWrapper}>
                        <iframe
                            src={videoUrl}
                            title='Video player'
                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                            referrerPolicy='strict-origin-when-cross-origin'
                            allowFullScreen
                            frameBorder="0"
                            className={classes.videoPlayer}
                        ></iframe>
                    </div>
                )}
            </div>

            <div className={classes.select}>
                {selectName?.map((item) => (
                    <div
                        key={item}
                        className={`${classes.option} ${item === selectValue ? classes.activeOption : ''
                            }`}
                        onClick={() => setSelectValue(item)}
                    >
                        {item}
                    </div>
                ))}
            </div>
            <div className={classes.detailsContent}>
                {selectValue === 'Описание' && (
                    <div className={classes.detailsContentText} dangerouslySetInnerHTML={{ __html: description }} />
                )}
                {selectValue === 'Характеристики' && (
                    <div className={classes.characteristics+' '+classes.detailsContentText}>
                        {body_items?.map((item, index) => (
                            <div key={index} className={classes.characteristicItem}>
                                <h3>{item.name}</h3>
                                <div dangerouslySetInnerHTML={{ __html: item.value }} />
                            </div>
                        ))}
                    </div>
                )}
                {selectValue === 'Доставка' && (
                    <div className={classes.delivery}>
                        <PaymentSelect />
                    </div>
                )}
            </div>
            {!!localStorage.getItem('visitedArr') && (
                <CatalogWrapper
                    name={'Смотрели ранее'}
                    link="generators"
                    flagNoAllGoods={true}
                    showModal={handleShowModal}
                    items={localStorage
                        .getItem('visitedArr')
                        ?.split(',')
                        .map((vis) => data.find((item) => item.id === +vis))
                        .filter((item) => item !== undefined)}
                />
            )}
            {showModal && <ModalCallback onClose={() => setShowModal(false)}
                                         modalSlug={modalGenId}
                                         type={'generators'}
            />}
        </div>
    );
};

export default GeneratorsItemPage;
