import React from "react"
import { useEffect, useContext, useState } from 'react'
import { ProfileContext } from '../../../App'
import { useGetGenerators } from '../../hooks/useGetGenerators'
import { useGetGenOurProjects } from '../../hooks/useGetGenOurProjects'
import PageSpinner from "../../common/pageSpinner/PageSpinner"
import Banner from '../../common/Banner/Banner'
import About from "../../common/advantagesGens/About"
import CatalogWrapper from "../../common/catalogWrapper/CatalogWrapper"
import Advertisement from "../../common/advertisement/Advertisement"
import banner from '../../../images/Container_01.jpg';
import ModalCallback from "../../common/ModalCallback/ModalCallback"
import ImageViewer from "../../common/imageViewer/ImageViewer"
import styles from './ContainersPage.module.scss'
import AboutContainersInfo from "../../common/aboutContainersInfo/AboutContainersInfo";
import {getInfoContainers} from "../../utils/api";
import {imageUrl} from "../../config/config";

const ContainersPage = () => {
  const context = useContext(ProfileContext)
  const { data: genData, isLoading: genLoader } = useGetGenerators('/containers')
  const { projects, loading: projectsLoading, error } = useGetGenOurProjects('/our-pr-containers');
  const [pageInfo, setPageInfo] = useState({about_us:{},banner:{},gallery:{items:[]}})

  useEffect(() => {
    context.storeDispatch({ type: 'mining_manager', payload: 'mining' })
    return () => {
      context.storeDispatch({ type: 'regular' })
    }
  }, [])

  useEffect(()=>{
    const getInfo = async ()=>{
      const res = await getInfoContainers()
      setPageInfo(res)
    }
    getInfo()
  },[])


  const flattenedMediaItems = projects.flatMap((project) =>
    project.mediaItems.map((media) => ({
      ...media,
      projectId: project.id,
      projectTitle: project.title,
      projectDescription: project.description,
      projectDocuments: project.documents,
    }))
  );
  const bannerData = {
    image: banner,
    title: "Контейнеры для майнинга",
    description: "Предлагаем вам мобильные дата-центры для оборудования с воздушным или водяным охлаждением.",
    crumsData: [['Containers', 'Контейнеры']]
  }
  const [showModal, setShowModal] = useState(false);
  const [modalGenId, setModalGenId] = useState(null);

  const handleShowModal = (visible, slug) => {
    setShowModal(visible);
    setModalGenId(slug); // Сохраняем переданный slug_name
  };
  return (
    <>
      {
        genLoader && projectsLoading ? (
          <PageSpinner />
        ) : (
          <div className={`${styles.main} container`}>
            <Banner {...bannerData} showModal={setShowModal} />
            <CatalogWrapper
                name=""
                link="/containers"
                items={genData}
                isLoading={genLoader}
                flagNoAllGoods={true}
                showModal={handleShowModal}
            />
            <About image={imageUrl+pageInfo.about_us.store_url} title={pageInfo.about_us.title} description={pageInfo.about_us.description} />
            {flattenedMediaItems.length > 0 && <h3 className={styles.headerPr}>Модели и характеристики</h3>}
            <ImageViewer
              mediaItems={flattenedMediaItems}
              additionalInfo={true} />
            <Advertisement
              image={imageUrl+pageInfo.banner.store_url}
              title={pageInfo.banner.title}
              subtitle={pageInfo.banner.description}
              type={'containers'}
            />
            <ImageViewer mediaItems={pageInfo.gallery.items.map(item => ({
              id: item.id,
              type: item.media_type,
              url: `${imageUrl}${item.store_url}`,
              description: item.description,
              }))} />
            <div className={styles.whyUs}>
              <div className={styles.whyUsText}>Почему мы</div>
              <AboutContainersInfo />
            </div>
            {showModal && <ModalCallback onClose={() => setShowModal(false)}
                                         modalSlug={modalGenId}
                                         type={'containers'}
            />}
          </div>
        )
      }
    </>
  );
}

export default ContainersPage