import classes from './InvestionBlock.module.scss'
import ArrowBunner from '../../../accests/image/sliderArrow.svg'
import { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import CatalogListItem from '../catalogMenu/CatalogListItem/CatalogListItem'
// import { catalogListNameMenu } from '../catalogMenu/CatalogMenu'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useGetMarketInfo } from '../../hooks/useGetMarketInfo'
import { imageUrl } from '../../config/config'

const InvestionBlock = ({data}) => {
   function SampleNextArrow(props) {
      const { onClick } = props
      return (
         <div className={`${classes.wrapperArrow} ${classes.rightPos}`} onClick={onClick}>
            <img
               className={`${classes.arrowImg} ${classes.rotateImg}`}
               src={ArrowBunner}
               alt='rightArrow'
            />
         </div>
      )
   }

   function SamplePrevArrow(props) {
      const { onClick } = props
      return (
         <div className={`${classes.wrapperArrow} ${classes.leftPos}`} onClick={onClick}>
            <img className={classes.arrowImg} src={ArrowBunner} alt='leftArrow' />
         </div>
      )
   }

   const [currentSlide, setCurrentSlide] = useState(0)
   const sliderRef = useRef(null)

   const settings = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 400,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      dots: true,
      appendDots: (dots) => (
         <div
            style={{
               position: 'absolute',
               top: '16px',
               display: 'flex',
               justifyContent: 'center',
               height: '0px',
               margin: 0,
            }}
         >
            <ul className={classes.dots}> {dots} </ul>
         </div>
      ),
      customPaging: (i) => (
         <div className={`${classes.dot} ${i === currentSlide ? classes.active : ''}`} />
      ),
      afterChange: (current) => setCurrentSlide(current),
   }

   const handleWheel = (e) => {
      e.preventDefault() // предотвращаем стандартную прокрутку
      const slider = sliderRef.current?.innerSlider
      if (slider) {
         if (e.deltaY < 0) {
            slider.slickPrev()
         } else if (e.deltaY > 0) {
            slider.slickNext()
         }
      }
   }

   useEffect(() => {
      const sliderNode = sliderRef.current?.innerSlider?.list
      if (sliderNode) {
         sliderNode.addEventListener('wheel', handleWheel, { passive: false })
         return () => {
            sliderNode.removeEventListener('wheel', handleWheel)
         }
      }
   }, [])

   //const brands = data?.brands
   const banners = data?.banners

   const catalogListNameMenu = [
      {
         name: 'Whatsminer',
         link: `/asic-miners?company=1`,
      },
      {
         name: 'Bitmain',
         link: `/asic-miners?company=2`,
      },
      {
         name: 'ElphaPex',
         link: `/asic-miners?company=5`,
      },
      {
         name: 'Iceriver',
         link: `/asic-miners?company=4`,
      },
      {
         name: 'Canaan',
         link: `/asic-miners?company=9`,
      },
      {
         name: 'Посмотреть все',
         link: '/asic-miners',
      },
   ]

   return (
      <div className={classes.wrapper + ' container'}>
         <div className={classes.catalogList} onClick={(e) => e.stopPropagation()}>
            <div className={classes.catalogListItem}>
               {catalogListNameMenu.map((item,number) => (
                  <CatalogListItem
                     closedModal={() => {}}
                     link={item.link}
                     name={item.name}
                     key={number}
                  />
               ))}
            </div>
         </div>
         <div className={classes.sliderWrapper}>
            <Slider {...settings} className={classes.slider} ref={sliderRef}>
               {banners?.map((item) => (
                  <Link to={item.link} key={item.id}>
                     <img src={`${imageUrl}${item.store_url}`} alt='imageBanner' />
                  </Link>
               ))}
            </Slider>
         </div>
      </div>
   )
}

export default InvestionBlock
