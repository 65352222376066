import React, {useEffect, useState} from 'react';
import classes from "./popupBunner.module.css";
import {CSSTransition} from "react-transition-group";
import {useNavigate} from "react-router-dom";
import image from './image.png'
import { parsePhoneNumberFromString } from "libphonenumber-js";
import {setCallMe} from "../../utils/api";

const PopupBanner = () => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const [value, setValue] = useState('')
    const [not, setNot] = useState(0)

    useEffect(()=>{
        const isShown = localStorage.getItem("modal_shown");
        if(!isShown){
            let timer = setTimeout(()=>{setOpen(true);localStorage.setItem("modal_shown", "true");},30000)
            return () => clearTimeout(timer);
        }},[])

    function scrollup(url = '/') {
        window.scrollTo(0, 0)
        navigate(url)
    }

    function phoneFormating(tel) {
        const parsedNumber = parsePhoneNumberFromString(tel.replace(/^8/, "+7"));
        return parsedNumber && parsedNumber.isValid()
    }

    async function send(){
        if(phoneFormating(value)){
            await setCallMe(value)
            setNot(2)
            setOpen(false)
            setTimeout(()=>setNot(0), 3000)
        }else{
            setNot(1)
            setTimeout(()=>setNot(0), 3000)
        }
    }

    return (
        <CSSTransition in={open}
                       timeout={300}
                       classNames="block"
                       unmountOnExit
        >
            <div className={classes.fon} onClick={()=>setOpen(false)}>
                <div className={classes._} onClick={e=>e.stopPropagation()}>
                    <img src={image} alt={''} className={classes.image}/>
                    <div className={classes.close} onClick={()=>setOpen(false)}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M19.2803 4.71967C19.5732 5.01256 19.5732 5.48744 19.2803 5.78033L5.78033 19.2803C5.48744 19.5732 5.01256 19.5732 4.71967 19.2803C4.42678 18.9874 4.42678 18.5126 4.71967 18.2197L18.2197 4.71967C18.5126 4.42678 18.9874 4.42678 19.2803 4.71967Z" fill="#80899C"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M4.71967 4.71967C5.01256 4.42678 5.48744 4.42678 5.78033 4.71967L19.2803 18.2197C19.5732 18.5126 19.5732 18.9874 19.2803 19.2803C18.9874 19.5732 18.5126 19.5732 18.2197 19.2803L4.71967 5.78033C4.42678 5.48744 4.42678 5.01256 4.71967 4.71967Z" fill="#80899C"/>
                        </svg>
                    </div>
                    <div className={classes.block}>
                        <div className={classes.text1}>Мы перезвоним</div>
                        <div className={classes.text2}>Оставьте свой номер телефона и мы предложим вам лучшие цены, актуальные скидки и самые эффективные модели!</div>
                        <div className={classes.text3}>Номер телефона</div>
                        <input className={classes.input}
                               placeholder={'+79999999999'}
                               value={value}
                               onChange={e=>{setValue(e.target.value); phoneFormating(e.target.value)}}
                        />
                        <div className={classes.button} onClick={send}>Отправить</div>
                        <div className={classes.text4}>Нажимая на кнопку, вы даете согласие на обработку персональных данных и соглашаетесь
                            c <span className={classes.text5} onClick={() =>{scrollup('/rights'); setOpen(false)}}>политикой конфиденциальности</span></div>
                    </div>
                </div>
                <CSSTransition in={not === 1}
                               timeout={300}
                               classNames="block"
                               unmountOnExit
                >
                    <div className={classes.not}>
                        Номер введен неверно
                    </div>
                </CSSTransition>
                <CSSTransition in={not === 2}
                               timeout={300}
                               classNames="block"
                               unmountOnExit
                >
                    <div className={classes.not}>
                        Отправлено!
                    </div>
                </CSSTransition>
            </div>
        </CSSTransition>
    );
};

export default PopupBanner;