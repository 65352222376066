import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import SidePopup from '../modalsUtility/SidePopup';
import ImgSucc from '../../../accests/image/success.svg';
import classes from './ModalCallback.module.scss';
import {
    postContainersSendFeedback,
    postGeneratorSendFeedback,
    postOrderAsicSendFeedback
} from '../../hooks/usePostSendFeedback';
import { phoneFormating } from '../comFn';
import ReactDOM from "react-dom";
import ym from 'react-yandex-metrika';
import { YMInitializer } from 'react-yandex-metrika';

const ModalCallback = ({ onClose, modalSlug = null, type, flags=[] }) => {
    const navigate = useNavigate()
    const [state, setState] = useState({
        name: '',
        phones: [{ messenger: 'phone', phone: '' }], // Список выбранных мессенджеров с номерами
        generator_id: modalSlug || null,
        container_id: modalSlug || null,
        item_id: modalSlug || null,
        flag_ids: flags,
        utm: localStorage.getItem('utm')
    });
    const [popup, setPopup] = useState(false);
    const [textModal, setTextModal] = useState('');
    useEffect(() => {
        if (modalSlug) {
            setState((prevState) => ({
                ...prevState,
                generator_id: modalSlug,
                item_id: modalSlug
            }));
        }
    }, [modalSlug]);
    const handleCheckboxChange = (method) => {
        setState((prevState) => {
            const phones = prevState.phones.some((phone) => phone.messenger === method)
                ? prevState.phones.filter((phone) => phone.messenger !== method) // Убираем метод, если он был выбран
                : [...prevState.phones, { messenger: method, phone: '' }]; // Добавляем новый метод, если не выбран
            return { ...prevState, phones };
        });
    };

    const handlePhoneChange = (method, value) => {
        setState((prevState) => {
            const phones = prevState.phones.map((phone) =>
                phone.messenger === method
                    ? { ...phone, phone: method === 'phone' ? phoneFormating(value) : value }
                    : phone
            );
            return { ...prevState, phones };
        });
    };

    const isFormValid = state.name && state.phones.some((phone) => phone.phone);

    const sendData = () => {
        if (!isFormValid) {
            setTextModal('Заполните все обязательные поля!');
            setPopup(true);
            return;
        }
        if(type === 'generators'){
            if(state.generator_id){
                ym('reachGoal','gen_order')
            }else{
                ym('reachGoal','gen_consult_button')
            }
            postGeneratorSendFeedback({
                state,
                setTextModal,
                setPopup,
                setState,
                onClose,
            });
        }
        if(type === 'containers'){
            if(state.generator_id){
                ym('reachGoal','container_order')
            }else{
                ym('reachGoal','container_consult_button')
            }
            postContainersSendFeedback({
                state,
                setTextModal,
                setPopup,
                setState,
                onClose,
                flags
            });
        }
        if(type === 'asic'){
            postOrderAsicSendFeedback({
                state,
                setTextModal,
                setPopup,
                setState,
                onClose,
            });
        }
    };

    function scrollup(url = '/') {
        window.scrollTo(0, 0)
        navigate(url)
    }
    useEffect(() => {
        //if (popup) {
        //    setTimeout(() => setPopup(false), 2100);
       // }
    }, [popup]);
    return ReactDOM.createPortal(
        <>
            <YMInitializer accounts={[98425267]} />
            <div className={classes.overlay} onClick={(e) => e.target === e.currentTarget && onClose()}>
                <div className={classes.modalCentered}>
                    <div className={classes.modalContent}>
                        <button className={classes.closeButton} onClick={onClose}>
                            ✕
                        </button>
                        <div className={classes.modalTitle}>Оставьте свои данные</div>
                        <p className={classes.modalDescription}>и мы свяжемся с вами в ближайшее время</p>
                        <input
                            type="text"
                            placeholder="Ваше имя"
                            value={state.name}
                            onChange={(e) => {
                                setState({ ...state, name: e.target.value });
                            }}
                            className={classes.input}
                        />
                        <div className={classes.checkboxGroup}>
                            <label className={classes.checkboxLabel}>
                                <input
                                    type="checkbox"
                                    checked={state.phones.some((phone) => phone.messenger === 'phone')}
                                    onChange={() => handleCheckboxChange('phone')}
                                />
                                Телефон
                            </label>
                            <label className={classes.checkboxLabel}>
                                <input
                                    type="checkbox"
                                    checked={state.phones.some((phone) => phone.messenger === 'telegram')}
                                    onChange={() => handleCheckboxChange('telegram')}
                                />
                                Telegram
                            </label>
                            <label className={classes.checkboxLabel}>
                                <input
                                    type="checkbox"
                                    checked={state.phones.some((phone) => phone.messenger === 'whatsapp')}
                                    onChange={() => handleCheckboxChange('whatsapp')}
                                />
                                WhatsApp
                            </label>
                        </div>
                        {state.phones.map((phone) => {
                            if (phone.messenger === 'phone') {
                                return (
                                    <input
                                        key="phone"
                                        type="text"
                                        placeholder="Ваш телефон"
                                        value={phone.phone}
                                        onChange={(e) => handlePhoneChange('phone', e.target.value)}
                                        className={classes.input}
                                    />
                                );
                            }
                            if (phone.messenger === 'telegram') {
                                return (
                                    <input
                                        key="telegram"
                                        type="text"
                                        placeholder="@Ник в Telegram"
                                        value={phone.phone}
                                        onChange={(e) => handlePhoneChange('telegram', e.target.value)}
                                        className={classes.input}
                                    />
                                );
                            }
                            if (phone.messenger === 'whatsapp') {
                                return (
                                    <input
                                        key="whatsapp"
                                        type="text"
                                        placeholder="Ваш номер в WhatsApp"
                                        value={phone.phone}
                                        onChange={(e) => handlePhoneChange('whatsapp', e.target.value)}
                                        className={classes.input}
                                    />
                                );
                            }
                            return null;
                        })}
                        <button onClick={sendData}
                            className={`${classes.submitButton} ${!isFormValid ? classes.disabled : ''}`}
                            disabled={!isFormValid}>
                            Отправить запрос
                        </button>
                        <p className={classes.privacyPolicy}>
                            Нажимая на кнопку, вы соглашаетесь с <a href="#" onClick={() => scrollup('/rights')}>Политикой конфиденциальности</a>
                        </p>
                    </div>
                </div>
            </div>
            {popup ? (
                <SidePopup dataState={true}>
                    <span className={classes.popupWrapper}>
                        <img src={ImgSucc} alt="success" />
                        <p>{textModal}</p>
                    </span>
               </SidePopup>
            ) : null}
        </>
    , document.body);
};

export default ModalCallback;
