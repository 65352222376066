import classes from './ContainerItem.module.scss'
import DefaultImage from '../../../../images/gal1.png'
import Button from '../Button/Button'
import { text } from '../../../../translate'
import {
    addRemoveCompareById,
    addRemoveVisitedById,
    formatingNumber,
} from '../../comFn'
import { imageUrl } from '../../../config/config'
import { useNavigate } from 'react-router-dom'
import ModalCallback from "../../ModalCallback/ModalCallback";
import {useState} from "react";

const ContainerItem = ({
    id,
    title,
    slug_name,
    head_items,
    card_head,
    video_url,
    price_flags,
    price,
    discount,
    total_price,
    medias,
    health_state,
    is_in_stock,
    tags
}) => {
    const lang = 'ru'
    const navigate = useNavigate()

    const handleClickLink = () => {
        window.scroll(0, 0)
        addRemoveVisitedById(id)
        navigate(`/containers/${slug_name}`)
    }
    const [showModal, setShowModal] = useState(false);
    const showModalForm = () => {
        setShowModal(true);
    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.mainImage} onClick={() => addRemoveVisitedById(id)}>
                <div onClick={handleClickLink} className={classes.linkImg}>
                    <img
                        className={classes.imageItem}
                        src={!!medias?.length ? `${imageUrl}${medias[0].store_url}` : DefaultImage}
                        alt={title}
                    />
                </div>
            </div>
            <div className={classes.name}>{title}</div>
            <div className={classes.description} onClick={handleClickLink}>
                <div className={classes.descriptionValue} dangerouslySetInnerHTML={{ __html: card_head }}></div>
            </div>

            <div className={classes.labels}>
                {health_state && (
                    <div className={classes.otherTag}
                        style={{
                            color: health_state === 'new' ? '#4CAF50' : '#FF9800',
                            border: `1px solid ${health_state === 'new' ? '#4CAF50' : '#FF9800'}`
                        }}>
                        {health_state === 'new' ? 'Новый' : 'Б/У'}
                    </div>
                )}
                {!!is_in_stock && (
                    <div className={classes.hit}>{text[lang].mainPage.catalogItem.is_in_stock}</div>
                )}
                {tags?.map((tag) => (
                    <div
                        className={classes.otherTag}
                        key={tag.id}
                        style={{
                            color: tag.color ?? '#80899c',
                            border: `1px solid ${tag.color ?? '#80899c'}`,
                        }}
                    >
                        {tag?.name}
                    </div>
                ))}
            </div>

            <div className={classes.pricesWrapper}>
                <div className={classes.discountWrapper}>
                    {!!discount && !!total_price && (
                        <>
                            <div className={classes.price}>{formatingNumber(price)}</div>
                            <div className={classes.salleryPrice}>-{formatingNumber(discount)} ₽</div>
                        </>
                    )}
                </div>
                {!!total_price && <div className={classes.total_price}>{formatingNumber(total_price)} {total_price && <>₽</>}</div>}
            </div>

            <div className={classes.activeBlock}>
                <Button
                    itemId={id}
                    photo={medias}
                    name={title}
                    variant="inverted"
                    onClick={handleClickLink}>
                    Подробнее
                </Button>
                <Button itemId={id} photo={medias} name={title} onClick={showModalForm}>
                    {/*<img src={ShopIcon} alt='shopIcon' />*/}Заказать
                </Button>
                {/*<div
                    className={`${classes.labelActive} ${localStorage
                        .getItem('compareArr')
                        ?.split(',')
                        .filter((item) => +item === +id).length
                        ? classes.compareActive
                        : ''
                        }`}
                    onClick={() => addRemoveCompareById(id)}
                >
                    <img src={ComparisonIcon} alt='ComparisonIcon' />
                </div>*/}
                {/*<ButtonFavorite itemId={id} />*/}
            </div>
            {showModal && <ModalCallback modalSlug={id}
                                         type={'containers'}
                                         onClose={() => setShowModal(false)} />}
        </div>
    )
}

export default ContainerItem