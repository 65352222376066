import { useState, useEffect } from 'react';
import classes from './CatalogWrapper.module.scss'
import CatalogItem from './CatalogItem/CatalogItem'
import GeneratorItem from './GeneratorItem/GeneratorItem'
import { text } from '../../../translate'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import { Navigation } from 'swiper/modules'
import ContainerItem from "./ContainerItem/ContainerItem";


const ITEM_COMPONENTS = {
   'generators': GeneratorItem,
   'asic-miners': CatalogItem,
   'containers': ContainerItem
}


const CatalogWrapper = ({ name, link, items, isLoading, showModal, flagNoAllGoods = false, }) => {
   const lang = 'ru'
   const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 1200);

   useEffect(() => {
      const handleResize = () => {
         setWindowWidth(window.innerWidth);
      };

      if (typeof window !== 'undefined') {
         window.addEventListener('resize', handleResize);
         return () => window.removeEventListener('resize', handleResize);
      }
   }, []);
   const getItemComponent = () => {
      if (link === 'none') return CatalogItem
      const type = link.split('/').filter(Boolean)[0]
      return ITEM_COMPONENTS[type] || CatalogItem
   }

   const ItemComponent = getItemComponent()


   const breakpoints = {
      320: {
         slidesPerView: 'auto',
         spaceBetween: 10,
      },
      700: {
         slidesPerView: 2,
         spaceBetween: 20,
      },
      1100: {
         slidesPerView: 3,
         spaceBetween: 20,
      },
      1280: {
         slidesPerView: 4,
         spaceBetween: 20,
      },
   }

   const renderSlider = () => (
      <Swiper
         slidesPerView={windowWidth <= 768 ? 'auto' : 4}
         spaceBetween={windowWidth <= 768 ? 10 : 20}
         navigation={windowWidth > 768 ? {
            nextEl: `.${classes.rightPos}`,
            prevEl: `.${classes.leftPos}`,
         } : false}
         breakpoints={breakpoints}
         modules={[Navigation]}
         className={windowWidth <= 768 ? classes.mobileSliderWrapper : classes.sliderWrapper}
         freeMode={windowWidth <= 768}
         observer={true}
         observeParents={true}
      >
         {items.map((item) => (
            <SwiperSlide
               key={item.id}
               className={windowWidth <= 768 ? classes.mobileSwiperSlide : classes.swiperSlide}
            >
               <ItemComponent {...item} isLoading={isLoading} showModal={showModal} />
            </SwiperSlide>
         ))}
      </Swiper>
   );


   return (
      <>
         {isLoading ? (
            <div className={classes.wrapper + ' container'}>Загрузка...</div>
         ) : (
            <>
               {windowWidth > 768 ? (
                  <div className={classes.wrapper + ' container'}>
                     <div className={classes.title}>
                        <div className={classes.nameBlock}>{name}</div>
                        {(link !== 'none' && !flagNoAllGoods) && (
                           <a className={classes.linkForPage} href={link}>
                              <p>{text[lang].mainPage.allItems}</p>
                              <svg
                                 width='7'
                                 height='10'
                                 viewBox='0 0 7 10'
                                 xmlns='http://www.w3.org/2000/svg'
                              >
                                 <path d='M6.41665 5.37052L1.6685 9.34547C1.27419 9.67557 0.599976 9.44178 0.599976 8.97495L0.599975 1.02505C0.599975 0.558216 1.27419 0.324425 1.6685 0.654526L6.41665 4.62948C6.66108 4.83411 6.66109 5.16589 6.41665 5.37052Z' />
                              </svg>
                           </a>
                        )}
                     </div>
                     <Swiper
                        slidesPerView={4}
                        spaceBetween={20}
                        navigation={{
                           nextEl: `.${classes.rightPos}`,
                           prevEl: `.${classes.leftPos}`,
                        }}
                        breakpoints={breakpoints}
                        modules={[Navigation]}
                        className={classes.sliderWrapper}
                     >
                        {items.map((item) => (
                           <SwiperSlide key={item.id} className={classes.swiperSlide}>
                              <ItemComponent {...item} isLoading={isLoading} showModal={showModal} />
                           </SwiperSlide>
                        ))}
                     </Swiper>
                     <div className={`${classes.wrapperArrow} ${classes.rightPos}`}>
                        <svg
                           width='7'
                           height='10'
                           viewBox='0 0 7 10'
                           xmlns='http://www.w3.org/2000/svg'
                        >
                           <path d='M6.41665 5.37052L1.6685 9.34547C1.27419 9.67557 0.599976 9.44178 0.599976 8.97495L0.599975 1.02505C0.599975 0.558216 1.27419 0.324425 1.6685 0.654526L6.41665 4.62948C6.66108 4.83411 6.66109 5.16589 6.41665 5.37052Z' />
                        </svg>
                     </div>
                     <div className={`${classes.wrapperArrow} ${classes.leftPos}`}>
                        <svg
                           width='7'
                           height='10'
                           viewBox='0 0 7 10'
                           xmlns='http://www.w3.org/2000/svg'
                        >
                           <path d='M6.41665 5.37052L1.6685 9.34547C1.27419 9.67557 0.599976 9.44178 0.599976 8.97495L0.599975 1.02505C0.599975 0.558216 1.27419 0.324425 1.6685 0.654526L6.41665 4.62948C6.66108 4.83411 6.66109 5.16589 6.41665 5.37052Z' />
                        </svg>
                     </div>
                  </div>
               ) : (
                  <div className='container'>
                     <div className={classes.title}>
                        <div className={classes.nameBlock}>{name}</div>
                        {(link !== 'none' && !flagNoAllGoods) && (
                           <a className={classes.linkForPage} href={link}>
                              <p>{text[lang].mainPage.allItems}</p>
                              <svg
                                 width='7'
                                 height='10'
                                 viewBox='0 0 7 10'
                                 xmlns='http://www.w3.org/2000/svg'
                              >
                                 <path d='M6.41665 5.37052L1.6685 9.34547C1.27419 9.67557 0.599976 9.44178 0.599976 8.97495L0.599975 1.02505C0.599975 0.558216 1.27419 0.324425 1.6685 0.654526L6.41665 4.62948C6.66108 4.83411 6.66109 5.16589 6.41665 5.37052Z' />
                              </svg>
                           </a>
                        )}
                     </div>
                     {/*<div className={classes.mobileSliderWrapper + ' container'}>
                        {items.map((item) => (
                           <ItemComponent {...item} key={item.id} isLoading={isLoading} />
                        ))}
                     </div>*/}
                     <div className={`${classes.mobileSliderContainer}`}>
                        {renderSlider()}
                     </div>
                  </div>
               )}
            </>
         )}
      </>
   )
}

export default CatalogWrapper
