import React, { useEffect, useState } from 'react'
import { CurrentCompanyRate } from './CurrentCompanyRate/CurrentCompanyRate'
import Bitcoin from '../../../../accests/image/bitcoin.svg'
import Ethereum from '../../../../accests/image/ethereum.svg'
import BNB from '../../../../accests/image/bnbIcon.svg'
import Kaspa from '../../../../accests/image/Kaspa.png'
import Litecoin from '../../../../accests/image/Litecoin.png'
import Tonkoin from '../../../../accests/image/toncoin.svg'
import { useGetCurses } from '../../../hooks/useGetCurses'
import classes from './Courses.module.scss'

const Courses = () => {
   const { data } = useGetCurses()
   const [companyArr, setCompanyArr] = useState([])

   useEffect(() => {
      data?.bitcoin
         ? setCompanyArr([
              {
                 image: Bitcoin,
                 name: 'Bitcoin',
                 rate: Math.floor(data?.bitcoin?.usd_24h_change * 100) / 100,
                 direction: data?.bitcoin?.usd_24h_change < 0 ? 'short' : 'long',
                 value: data?.bitcoin.usd,
              },
              {
                 image: Ethereum,
                 name: 'Ethereum',
                 rate: Math.floor(data?.ethereum?.usd_24h_change * 100) / 100,
                 direction: data?.ethereum?.usd_24h_change < 0 ? 'short' : 'long',
                 value: data?.ethereum.usd,
              },
              {
                 image: BNB,
                 name: 'BNB',
                 rate: Math.floor(data?.binancecoin?.usd_24h_change * 100) / 100,
                 direction: data?.binancecoin?.usd_24h_change < 0 ? 'short' : 'long',
                 value: data?.binancecoin.usd,
              },

              {
                 image: Tonkoin,
                 name: 'Toncoin',
                 rate: Math.floor(data?.['the-open-network']?.usd_24h_change * 100) / 100,
                 direction: data?.['the-open-network']?.usd_24h_change < 0 ? 'short' : 'long',
                 value: data?.['the-open-network'].usd,
              },
              {
                 image: Kaspa,
                 name: 'Kaspa',
                 rate: Math.floor(data?.kaspa?.usd_24h_change * 100) / 100,
                 direction: data?.kaspa?.usd_24h_change < 0 ? 'short' : 'long',
                 value: data?.kaspa.usd,
              },
              {
                 image: Litecoin,
                 name: 'Litecoin',
                 rate: Math.floor(data?.litecoin?.usd_24h_change * 100) / 100,
                 direction: data?.litecoin?.usd_24h_change < 0 ? 'short' : 'long',
                 value: data?.litecoin.usd,
              },
              {
                 image: Bitcoin,
                 name: 'Bitcoin',
                 rate: Math.floor(data?.bitcoin?.usd_24h_change * 100) / 100,
                 direction: data?.bitcoin?.usd_24h_change < 0 ? 'short' : 'long',
                 value: data?.bitcoin.usd,
              },
              {
                 image: Ethereum,
                 name: 'Ethereum',
                 rate: Math.floor(data?.ethereum?.usd_24h_change * 100) / 100,
                 direction: data?.ethereum?.usd_24h_change < 0 ? 'short' : 'long',
                 value: data?.ethereum.usd,
              },
              {
                 image: BNB,
                 name: 'BNB',
                 rate: Math.floor(data?.binancecoin?.usd_24h_change * 100) / 100,
                 direction: data?.binancecoin?.usd_24h_change < 0 ? 'short' : 'long',
                 value: data?.binancecoin.usd,
              },

              {
                 image: Tonkoin,
                 name: 'Toncoin',
                 rate: Math.floor(data?.['the-open-network']?.usd_24h_change * 100) / 100,
                 direction: data?.['the-open-network']?.usd_24h_change < 0 ? 'short' : 'long',
                 value: data?.['the-open-network'].usd,
              },
              {
                 image: Kaspa,
                 name: 'Kaspa',
                 rate: Math.floor(data?.kaspa?.usd_24h_change * 100) / 100,
                 direction: data?.kaspa?.usd_24h_change < 0 ? 'short' : 'long',
                 value: data?.kaspa.usd,
              },
              {
                 image: Litecoin,
                 name: 'Litecoin',
                 rate: Math.floor(data?.litecoin?.usd_24h_change * 100) / 100,
                 direction: data?.litecoin?.usd_24h_change < 0 ? 'short' : 'long',
                 value: data?.litecoin.usd,
              },
              {
                 image: Bitcoin,
                 name: 'Bitcoin',
                 rate: Math.floor(data?.bitcoin?.usd_24h_change * 100) / 100,
                 direction: data?.bitcoin?.usd_24h_change < 0 ? 'short' : 'long',
                 value: data?.bitcoin.usd,
              },
              {
                 image: Ethereum,
                 name: 'Ethereum',
                 rate: Math.floor(data?.ethereum?.usd_24h_change * 100) / 100,
                 direction: data?.ethereum?.usd_24h_change < 0 ? 'short' : 'long',
                 value: data?.ethereum.usd,
              },
              {
                 image: BNB,
                 name: 'BNB',
                 rate: Math.floor(data?.binancecoin?.usd_24h_change * 100) / 100,
                 direction: data?.binancecoin?.usd_24h_change < 0 ? 'short' : 'long',
                 value: data?.binancecoin.usd,
              },

              {
                 image: Tonkoin,
                 name: 'Toncoin',
                 rate: Math.floor(data?.['the-open-network']?.usd_24h_change * 100) / 100,
                 direction: data?.['the-open-network']?.usd_24h_change < 0 ? 'short' : 'long',
                 value: data?.['the-open-network'].usd,
              },
              {
                 image: Kaspa,
                 name: 'Kaspa',
                 rate: Math.floor(data?.kaspa?.usd_24h_change * 100) / 100,
                 direction: data?.kaspa?.usd_24h_change < 0 ? 'short' : 'long',
                 value: data?.kaspa.usd,
              },
              {
                 image: Litecoin,
                 name: 'Litecoin',
                 rate: Math.floor(data?.litecoin?.usd_24h_change * 100) / 100,
                 direction: data?.litecoin?.usd_24h_change < 0 ? 'short' : 'long',
                 value: data?.litecoin.usd,
              },
           ])
         : setCompanyArr([])
   }, [data, data?.bitcoin, data?.bitcoin?.usd_24h_change, data.length])
   return (
      <div className={classes.slider}>
         <div className={classes.sliderTrack}>
            {companyArr?.map((item, number) => (
               <CurrentCompanyRate {...item} key={number} className={classes.slide} />
            ))}
         </div>
      </div>
   )
}

export default Courses
