import { useEffect, useState } from 'react'
import axios from 'axios'
import { baseUrl, imageUrl } from '../config/config'

export const useGetGenOurProjects = (url) => {
    const [data, setData] = useState({
        projects: [],
        loading: true,
        error: null
    });

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await axios.get(`${baseUrl}${url}`);
                const data = response.data;
                const formattedData = data.map(project => ({
                    id: project.id,
                    title: project.title,
                    description: project.description,
                    documents: project.files.map(file => ({
                        id: file.id,
                        name: file.name,
                        url: `${imageUrl}${file.store_url}`,
                    })),
                    mediaItems: project.medias.map(media => ({
                        id: media.id,
                        type: media.media_type,
                        url: `${imageUrl}${media.store_url}`,
                    })),
                    createdAt: new Date(project.created_at),
                    updatedAt: new Date(project.updated_at),
                }));
                setData({
                    projects: formattedData,
                    loading: false,
                    error: null
                });

            } catch (err) {
                setData({
                    projects: [],
                    loading: false,
                    error: err
                });
            }
        };

        fetchProjects();
    }, []);

    return data;
};