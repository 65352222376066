import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProfileContext } from '../../../App'
import { FileText, Play } from 'lucide-react'
import Crums from '../../common/breadCrums/Crums';
import SmallSlider from '../../common/smallSlider/SmallSlider';
import CatalogWrapper from '../../common/catalogWrapper/CatalogWrapper';
import PageSpinner from '../../common/pageSpinner/PageSpinner';
import PaymentSelect from '../../common/descriptionItem/paymentSelect/PaymentSelect';
import AcceptIcon from '../../../accests/image/acceptIcon.svg'
import classes from './ContainersItemPage.module.scss';
import { useGetGenerators } from '../../hooks/useGetGenerators';
import { toKebabCase, formatingNumber } from '../../common/comFn';
import ModalCallback from '../../common/ModalCallback/ModalCallback';
import Switcher from "../../common/switcher/Switcher";

const ContainersItemPage = () => {
    const context = useContext(ProfileContext)
    const redirect = useNavigate();
    const params = useParams();
    const { data, isLoading } = useGetGenerators('/containers');
    const [selectValue, setSelectValue] = useState('Описание');
    const [showModal, setShowModal] = useState(false);
    const [modalGenId, setModalGenId] = useState(null);
    const [flags, setFlags] = useState([])
    const [addFlagSum, setAddFlagSum] = useState(0)


    useEffect(() => {
        context.storeDispatch({ type: 'mining_manager', payload: 'mining' })
        return () => {
            context.storeDispatch({ type: 'regular' })
        }
    }, [])

    const dataT = data?.length
        ? data.find((item) => item.slug_name === params.title)
        : [];


    useEffect(() => {
        if (!dataT && !isLoading) {
            redirect('/Error404');
        }
    }, [dataT, isLoading, redirect]);


    const {
        id,
        title,
        price,
        discount,
        total_price,
        description,
        head_items,
        body_items,
        medias,
        attachments,
        tags,
        slug_name,
        is_active,
        video_url,
        card_head,
        price_flags
    } = dataT;

    useEffect(()=>{
        if(price_flags) {
            let temp = []
            price_flags.forEach(i=>{temp.push({...i, select:false})})
            setFlags(temp)
        }
    },[dataT])

    if (isLoading || !dataT) {
        return <PageSpinner />;
    }

    const handleDownload = (storeUrl) => {
        const link = document.createElement('a');
        link.href = storeUrl;
        link.setAttribute('download', '');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleShowModal = (visible, id) => {
        setShowModal(visible);
        setModalGenId(id); // Сохраняем переданный slug_name
    };

    const handleSelectFlag = (id) =>{
        let temp = []
        flags.forEach(i=>{
            if(i.id === id){
                temp.push({...i, select:!i.select})
            }else{
                temp.push({...i, select: i.select})
            }})
        setFlags(temp)
        let tempSum = 0
        temp.filter(i=>i.select).forEach(i=>tempSum+=i.value)
        setAddFlagSum(tempSum)
    };

    const selectName = ['Описание', 'Комплектация', 'Доставка'];



    const videoExtensions = ['.mp4', '.mov', '.avi', '.mkv'];

    /*const videoUrl = medias?.find((media) =>
        videoExtensions.some((ext) => media.store_url.toLowerCase().endsWith(ext))
    )?.store_url;*/
    const videoUrl = video_url;
    const imageUrls = medias?.filter((media) =>
        !videoExtensions.some((ext) => media.store_url.toLowerCase().endsWith(ext))
    ).map((media) => media.store_url);


    return (
        <div className={`container ${classes.pageContainer}`}>
            <Crums
                data={[
                    ['containers', 'Контейнеры'],
                    ['', `${title ?? '...'}`],
                ]}
            />
            {/* Основная структура для больших экранов */}
            <div className={classes.content}>
                <div className={classes.infoWrapper}>
                    <SmallSlider photo_urls={imageUrls} />

                    <div className={classes.documents}>
                        {attachments?.map((doc) => (
                            <div
                                className={classes.document}
                                key={doc.id}
                                onClick={() => handleDownload(doc.store_url)}
                            >
                                <div className={classes.documentLeft}>
                                    <FileText />
                                    <span>{doc.name}</span>
                                </div>
                                <span className={classes.downloadText}>Скачать</span>
                            </div>
                        ))}
                    </div>
                </div>

                <div className={classes.aboutItemInfo}>
                    <h1 className={classes.name}>{title}</h1>
                    <div className={classes.descriptionValue} dangerouslySetInnerHTML={{ __html: card_head }}></div>
                    <div className={classes.pricesWrapper}>
                        <div className={classes.discountWrapper}>
                            {!!discount && (
                                <div className={classes.contDis}>
                                    <div className={classes.price}>{formatingNumber(price+addFlagSum)}</div>
                                    <div className={classes.salleryPrice}>
                                        -{formatingNumber(discount)} ₽
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={classes.disWrap}>
                            {!!total_price && <div className={classes.total_price}>
                                {formatingNumber(total_price+addFlagSum)} ₽
                            </div>}
                            <div className={classes.inHave}>
                                {tags.filter(item=>item.name==='В НАЛИЧИИ').length > 0 ? (
                                    <>
                                        <div className={classes.acceptImg}>
                                            <img src={AcceptIcon} alt='accept' />
                                        </div>
                                        <p>Есть в наличии</p>
                                    </>
                                ) : (
                                    <p>Нет в наличии</p>
                                )}
                            </div>
                        </div>

                        <button className={classes.orderButton} onClick={() => handleShowModal(true, id)}>Заказать</button>
                        <p className={classes.policyText}>
                            Нажимая на кнопку, вы соглашаетесь с{' '}
                            <a href="/rights" className={classes.policyLink}>
                                политикой конфиденциальности
                            </a>
                        </p>
                    </div>
                    {videoUrl && (
                        <div className={classes.videoWrapper}>

                            <iframe
                                src={videoUrl}
                                title='Video player'
                                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                referrerPolicy='strict-origin-when-cross-origin'
                                allowFullScreen
                                frameBorder="0"
                                className={classes.videoPlayer}
                            ></iframe>

                        </div>
                    )}
                    <div>
                        <p className={classes.optionsHeader}>Дополнительные опции</p>
                        {flags.map(item=>
                            <div className={classes.optionBlock}>
                                <div
                                    className={`${classes.wrapper} ${item.select && classes.activeWrapper}`}
                                    onClick={() => handleSelectFlag(item.id)}
                                >
                                    <div className={`${classes.checkBox} ${item.select && classes.activeBox}`} />
                                </div>
                                <div className={classes.optionBlockText}>{item.name} (+{item.value}₽)</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {/* Новая структура для маленьких экранов */}
            <div className={classes.mobileContent}>
                <h1 className={classes.name}>{title}</h1>

                <SmallSlider photo_urls={imageUrls} />

                <div className={classes.headItems}>
                    <div className={classes.descriptionValue} dangerouslySetInnerHTML={{ __html: card_head }}></div>

                </div>
                <div className={classes.inHave}>
                    {tags.filter(item=>item.name==='В НАЛИЧИИ').length > 0 ? (
                        <>
                            <div className={classes.acceptImg}>
                                <img src={AcceptIcon} alt='accept' />
                            </div>
                            <p>Есть в наличии</p>
                        </>
                    ) : (
                        <p>Нет в наличии</p>
                    )}
                </div>
                <div>
                    <p className={classes.optionsHeader}>Дополнительные опции</p>
                    {flags.map(item=>
                        <div className={classes.optionBlock}>
                            <div
                                className={`${classes.wrapper} ${item.select && classes.activeWrapper}`}
                                onClick={() => handleSelectFlag(item.id)}
                            >
                                <div className={`${classes.checkBox} ${item.select && classes.activeBox}`} />
                            </div>
                            <div className={classes.optionBlockText}>{item.name} (+{item.value}₽)</div>
                        </div>
                    )}
                </div>
                <div className={classes.pricesWrapper}>
                    <div className={classes.discountWrapper}>
                        {!!discount && (
                            <div className={classes.contDis}>
                                <div className={classes.price}>{formatingNumber(price+addFlagSum)}</div>
                                <div className={classes.salleryPrice}>
                                    -{formatingNumber(discount)} ₽
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={classes.disWrap}>
                        {!!total_price && <div className={classes.total_price}>
                            {formatingNumber(total_price+addFlagSum)} ₽
                        </div>}

                    </div>

                    <button className={classes.orderButton} onClick={() => handleShowModal(true, id)}>Заказать</button>
                    <p className={classes.policyText}>
                        Нажимая на кнопку, вы соглашаетесь с{' '}
                        <a href="/rights" className={classes.policyLink}>
                            политикой конфиденциальности
                        </a>
                    </p>
                </div>

                <div className={classes.documents}>
                    {attachments?.map((doc) => (
                        <div
                            className={classes.document}
                            key={doc.id}
                            onClick={() => handleDownload(doc.store_url)}
                        >
                            <div className={classes.documentLeft}>
                                <FileText />
                                <span>{doc.name}</span>
                            </div>
                            <span>Скачать</span>
                        </div>
                    ))}
                </div>
                {videoUrl && (
                    <div className={classes.videoWrapper}>
                        <iframe
                            src={videoUrl}
                            title='Video player'
                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                            referrerPolicy='strict-origin-when-cross-origin'
                            allowFullScreen
                            frameBorder="0"
                            className={classes.videoPlayer}
                        ></iframe>
                    </div>
                )}
            </div>

            <div className={classes.select}>
                {selectName?.map((item) => (
                    <div
                        key={item}
                        className={`${classes.option} ${item === selectValue ? classes.activeOption : ''
                            }`}
                        onClick={() => setSelectValue(item)}
                    >
                        {item}
                    </div>
                ))}
            </div>
            <div className={classes.detailsContent}>
                {selectValue === 'Описание' && (
                    <div className={classes.detailsContentText} dangerouslySetInnerHTML={{ __html: description }} />
                )}
                {selectValue === 'Комплектация' && (
                    <div className={classes.characteristics+' '+classes.detailsContentText}>
                        {body_items?.map((item, index) => (
                            <div key={index} className={classes.characteristicItem}>
                                <h3>{item.name}</h3>
                                <div dangerouslySetInnerHTML={{ __html: item.value }} />
                            </div>
                        ))}
                    </div>
                )}
                {selectValue === 'Доставка' && (
                    <div className={classes.delivery}>
                        <PaymentSelect />
                    </div>
                )}
            </div>
            {!!localStorage.getItem('visitedArr') && (
                <CatalogWrapper
                    name={'Смотрели ранее'}
                    link="containers"
                    flagNoAllGoods={true}
                    showModal={handleShowModal}
                    items={localStorage
                        .getItem('visitedArr')
                        ?.split(',')
                        .map((vis) => data.find((item) => item.id === +vis))
                        .filter((item) => item !== undefined)}
                />
            )}
            {showModal && <ModalCallback onClose={() => setShowModal(false)}
                                         modalSlug={modalGenId}
                                         type={'containers'}
                                         flags={flags.filter(item=>item.select).map(item => item.id)}
            />}
        </div>
    );
};

export default ContainersItemPage;
